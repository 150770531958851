import * as React from 'react';

function ProfileIcon(props) {
  const color = props.color ? props.color : '#fffff';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 255 255 0"
          />
        </filter>
      </defs>
      <g
        filter="url(#prefix__a)"
        transform="translate(-32 -103)"
        fill="none"
        fillRule="evenodd">
        <g stroke={color} strokeWidth="2">
          <path d="M63.36 119c0 8.483-6.877 15.36-15.36 15.36-8.482 0-15.36-6.877-15.36-15.36 0-8.482 6.878-15.36 15.36-15.36 8.483 0 15.36 6.878 15.36 15.36z" />
          <path d="M54.131 114.9a6.13 6.13 0 01-6.13 6.131 6.13 6.13 0 01-6.132-6.13A6.13 6.13 0 0148 108.769a6.13 6.13 0 016.131 6.131zM37.39 129.992A12.017 12.017 0 0148 123.625c4.596 0 8.59 2.58 10.612 6.37" />
        </g>
      </g>
    </svg>
  );
}

export default ProfileIcon;
