import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Bookmark from '../classes/common/Bookmark';

const Details = props => {
  const info = props.videoInfo;
  const music = info.music?.length ? info.music.join(', ') : 'no music';
  const accessories = info.accessories?.length
    ? info.accessories.join(', ')
    : 'N/A';
  const style = info.style || 'N/A';
  const muscleGroup = info.muscle_group || 'Muscles';
  const muscles = info.muscles?.length ? info.muscles.join(', ') : 'N/A';
  const equipment =
    info?.equipment?.length || info?.equipment_groups?.length
      ? [...info.equipment, ...info.equipment_groups].join(', ')
      : 'N/A';
  const level = info.level || 'N/A';
  const duration = Math.round(info.expected_duration / 60);
  const startTime = moment(info.publish_date).format('ddd L LT');
  const description = info.info || 'N/A';
  const mappedInstructorImages = info.instructors.map(instructor => {
    return (
      <InstructorImg key={instructor.uuid}>
        <div className="img-container">
          <img
            src={
              instructor.image_url
                ? instructor.image_url
                : 'https://s3-us-west-1.amazonaws.com/inspire-studios-assets/instructor-images/inspire_logo_black_globe.png'
            }
            className="img"
            alt={`${instructor.first_name} Avatar`}
          />
        </div>
        <div className="instructor-name">{`${instructor.first_name} ${instructor.last_name}`}</div>
      </InstructorImg>
    );
  });

  return (
    <MainWrapper>
      <div className="top-container">
        <div className="title-container">
          <div>
            <div className="title">{info.name}</div>
            {info?.session_groups?.length ? (
              <div className="program-series-name">
                {info.session_groups[0].name}
              </div>
            ) : null}
          </div>
          <Bookmark videoInfo={info} style={bookmarkStyle} videoPage={true} />
        </div>
        <div>{mappedInstructorImages}</div>
        <div className="start-time">{startTime}</div>
      </div>
      <div className="info-session-container">
        <div className="info-session-title">Session Information</div>
        <div className="info-text-container">
          <span className="info-label">Style</span>
          <span className="info-value">{style}</span>
        </div>
        <div className="info-text-container">
          <span className="info-label">{muscleGroup}</span>
          <span className="info-value">{muscles}</span>
        </div>
        <div className="info-text-container">
          <span className="info-label">Equipment</span>
          <span className="info-value">{equipment}</span>
        </div>
        <div className="info-text-container">
          <span className="info-label">Fitness Level</span>
          <span className="info-value">{level}</span>
        </div>
        <div className="info-text-container">
          <span className="info-label">Accessories</span>
          <span className="info-value">{accessories}</span>
        </div>
        <div className="info-text-container">
          <span className="info-label">Music</span>
          <span className="info-value">{music}</span>
        </div>
        <div className="info-text-container">
          <span className="info-label">Duration</span>
          <span className="info-value">{`${duration} min`}</span>
        </div>
        <div className="info-description">{description}</div>
      </div>
    </MainWrapper>
  );
};

export default Details;

const bookmarkStyle = {
  position: 'initial',
  height: '24px',
  width: '24px',
  marginLeft: '20px',
  cursor: 'pointer'
};

const MainWrapper = styled.div`
  background-color: rgb(27, 28, 28);
  color: ${props => props.theme.colors.white_main};

  .top-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    border-bottom: 3px solid rgb(38, 38, 38);
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .program-series-name {
    font-size: 16px;
    color: ${props => props.theme.colors.orange_main};
    margin-bottom: 15px;
  }

  .start-time {
    align-self: flex-end;
    font-size: 12px;
    color: #888888;
    margin-top: -20px;
  }

  .info {
    &-session-container {
      margin-top: 20px;
      border-bottom: 3px solid rgb(38, 38, 38);
      padding-bottom: 20px;
    }

    &-session-title {
      color: ${props => props.theme.colors.white_main};
      font-size: 20px;
      margin-bottom: 20px;
    }

    &-text-container {
      display: flex;
      margin-bottom: 15px;
    }

    &-label {
      color: ${props => props.theme.colors.grey_6};
      font-size: 16px;
      width: 150px;
    }

    &-value {
      color: ${props => props.theme.colors.white_main};
      font-size: 16px;
    }

    &-description {
      color: ${props => props.theme.colors.white_main};
      font-size: 16px;
      max-width: 600px;
    }
  }
`;

const InstructorImg = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .img-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid ${props => props.theme.colors.orange_main};
    width: 40px;
    height: 40px;
    background-color: #f2f2f2;
    overflow: hidden;
    margin-right: 10px;
  }

  .img {
    width: 38px;
    height: 38px;
  }

  .instructor-name {
    font-size: 16px;
    color: ${props => props.theme.colors.white_main};
  }
`;
