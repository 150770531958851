import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

const ProfileInfo = () => {
  const history = useHistory();
  const user = useSelector(state => state.user);
  const userName = `${user.firstName ? user.firstName : '--'} ${
    user.lastName ? user.lastName : '--'
  }`;

  return (
    <div>
      <h2 className={'overview-section-header'}>Profile</h2>
      <div>
        <div className={'overview-section-data'}>{userName}</div>
      </div>
      <div className={'overview-section-data overview-section-data-sub'}>
        {user.userName ? user.userName : '--'}
      </div>
      <div
        className={'button'}
        onClick={() => {
          history.push('/account/profile');
        }}>
        Edit Profile
      </div>
    </div>
  );
};

export default ProfileInfo;
