import React, {useState} from 'react';

import ChangePasswordModal from '../../common/Modals/ChangePasswordModal';
import Error from '../../common/Error';
import UniversalButton from '../../common/UniversalButton';
import eyeHide from '../../../assets/images/eye-hide.png';
import eyeShow from '../../../assets/images/eye-show.png';
import {useForm} from 'react-hook-form';
import {user} from '../../../utilities/apiCalls';

const PasswordForm = props => {
  const {register, errors, handleSubmit, setError, clearErrors} = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [modalState, setModalState] = useState({
    showModal: false,
    successfulReset: false
  });

  const onSubmit = async event => {
    if (event.newPassword !== event.newPasswordConfirmation) {
      setError('password', {
        type: 'manual',
        message: 'Passwords do not match.'
      });

      return;
    }
    props.setProcessing(true);

    user
      .updatePassword({
        newPassword: event.newPassword,
        newPasswordConfirmation: event.newPasswordConfirmation
      })
      .then(payload => {
        setModalState({showModal: true, successfulReset: payload});
      });
  };

  const closeModal = () => {
    setModalState({
      showModal: false,
      successfulReset: false
    });
    props.setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ChangePasswordModal
        showModal={modalState.showModal}
        successfulReset={modalState.successfulReset}
        closeModal={closeModal}
      />
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>Password</label>
        <Error
          message={errors.password?.message ? errors.password.message : ''}
        />
      </div>
      <div style={{position: 'relative'}}>
        <input
          style={errors.password ? props.styles.hasError : props.styles.input}
          name={'newPassword'}
          type={showPassword ? 'text' : 'password'}
          ref={register({
            required: {value: true, message: 'New Password is required.'}
          })}
          onChange={() => clearErrors('password')}
        />
        <img
          src={showPassword ? eyeShow : eyeHide}
          style={props.styles.icon}
          onClick={() => setShowPassword(!showPassword)}
          alt={'Eye Icon to toggle password visibility.'}
        />
      </div>
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>Password</label>
        <Error
          message={errors.password?.message ? errors.password.message : ''}
        />
      </div>
      <div style={{position: 'relative'}}>
        <input
          style={errors.password ? props.styles.hasError : props.styles.input}
          name={'newPasswordConfirmation'}
          type={showPassword ? 'text' : 'password'}
          ref={register({
            required: {
              value: true,
              message: 'Password Confirmation is required.'
            }
          })}
          onChange={() => clearErrors('password')}
        />
        <img
          src={showPassword ? eyeShow : eyeHide}
          style={props.styles.icon}
          onClick={() => setShowPassword(!showPassword)}
          alt={'Eye Icon to toggle password visibility.'}
        />
      </div>
      <UniversalButton
        processing={props.processing}
        type={'submit'}
        label={'Change Password'}
      />
    </form>
  );
};

export default PasswordForm;
