import * as React from 'react';

function QuickStartIcon(props) {
  const color = props.color ? props.color : '#ffffff';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 255 255 0"
          />
        </filter>
      </defs>
      <g
        stroke={color}
        filter="url(#prefix__a)"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M15.68 8A7.68 7.68 0 11.32 8a7.68 7.68 0 0115.36 0z" />
        <path d="M12.87 8.492c.358-.288.358-.759 0-1.046l-3.026-2.43c-.358-.289-.65-.148-.65.311v5.284c0 .459.292.6.65.312l3.026-2.43zm-5.526 0c.359-.288.359-.759 0-1.046l-3.026-2.43c-.357-.289-.65-.148-.65.311v5.284c0 .459.293.6.65.312l3.026-2.43z" />
      </g>
    </svg>
  );
}

export default QuickStartIcon;
