import {
  SET_BILLING_INFO,
  SET_PAYMENT_STATE,
  SET_PROMO_STATE
} from './typeConstants';

export const setPaymentState = data => ({
  type: SET_PAYMENT_STATE,
  data
});

export const setPromoState = data => ({
  type: SET_PROMO_STATE,
  data
});

export const setBillingInfo = data => ({
  type: SET_BILLING_INFO,
  data
});
