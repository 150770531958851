export const getColors = () => {
  return {
    red_main: '#DA291C',
    red_error: '#CC0000',
    orange_main: '#FF631B', // -  Orange is used for action items - both links and icons that take the user to a new location or complete an action.
    teal_main: '#148187', // -  Teal is often used as representations of progression and time.
    green_success: '#1E8657',
    white_main: '#ffffff', // -  #FFFFFF is almost always used on non-actionable copy.
    grey_base: '#1B1C1C', // -  Inspire Fitness technology follows a dark theme with all screens having a base of Grey 1 (#1B1C1C).
    grey_2: '#121212',
    grey_3: '#242424',
    grey_4: '#373737',
    grey_disabled: '#727272', // -  #727272 is most often used in disabled action items
    grey_6: '#888888',
    black: '#000000'
  };
};

// -  Gradients are most often used to represent Fitness Levels: Gradient 1 = Advanced, Gradient 2 = Intermediate, Gradient 3 = Beginner
export const getGradients = () => {
  return {
    advanced: ['#ff631b', '#da291c'],
    intermediate: ['#6b0f73', '#2e067e'],
    beginner: ['#148187', '#0e325e'],
    grey_3_to_grey_base: ['#242424', '#1B1C1C'],
    grey_base_to_grey_3: ['#1B1C1C', '#242424'],
    live_card: ['#14818745', '#1B1C1C'],
    clearToBlack: ['#00000000', '#1b1c1c']
  };
};
