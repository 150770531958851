import * as React from 'react';

function StarIcon(props) {
  const color = props.color ? props.color : '#ffffff';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <path
        d="M24 0l7.416 15.028L48 17.438 36 29.134l2.834 16.516L24 37.854 9.168 45.65 12 29.134 0 17.438l16.584-2.41z"
        stroke={color}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2px"
      />
    </svg>
  );
}

export default StarIcon;
