import {
  SET_SELECTED_LIVE_DATE,
  SET_LIVE_SESSIONS,
  SET_ONDEMAND_RANGE,
  UPDATE_ONDEMAND_QUERY,
  SET_ONDEMAND_TRAINER,
  SET_BOOKMARKED,
  CLEAR_FILTERS,
  SET_ONDEMAND_FILTERS,
  SET_REFRESH_SESSIONS
} from './typeConstants';

export const setSelectedLiveDate = data => ({
  type: SET_SELECTED_LIVE_DATE,
  data
});

export const setLiveSesssions = data => ({
  type: SET_LIVE_SESSIONS,
  data
});

export const setOndemandFilters = data => ({
  type: SET_ONDEMAND_FILTERS,
  data
});

export const setOndemandRange = data => ({
  type: SET_ONDEMAND_RANGE,
  data
});

export const setOndemandTrainer = data => ({
  type: SET_ONDEMAND_TRAINER,
  data
});

export const setBookmarked = data => ({
  type: SET_BOOKMARKED,
  data
});

export const clearFilters = data => ({
  type: CLEAR_FILTERS,
  data
});

export const updateOndemandQuery = () => ({
  type: UPDATE_ONDEMAND_QUERY
});

export const setRefreshSessions = data => ({
  type: SET_REFRESH_SESSIONS,
  data
});
