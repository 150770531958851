import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {sessions} from '../../utilities/apiCalls';
import VideoCard from '../classes/Ondemand/videoCard';

const OndemandList = props => {
  const liveSessions = useSelector(state => state.filters.live.sessions);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const instructorUUIDS = props.videoInfo.instructors.map(item => item.uuid);
    const equipment = [
      ...props.videoInfo.equipment,
      ...props.videoInfo.equipment_groups
    ];
    const query = `?session_statuses=Accessible&vod_statuses=Approved&is_published=true&sort_by=c.publish_date,desc&instructor_uuids=${instructorUUIDS}&all_equipment=${equipment}&page=0&limit=20`;
    sessions.get.query(query).then(payload => {
      if (!payload.error) {
        setVideos(payload.data);
      } else {
        setVideos([]);
      }
    });
  }, [props.videoInfo]);

  return (
    <MainWrapper>
      <h2>Similar On Demand Videos</h2>
      <VideoContainer liveSessions={liveSessions}>
        {videos.map(video => (
          <VideoCard key={video.uuid} videoInfo={video} />
        ))}
      </VideoContainer>
    </MainWrapper>
  );
};

export default OndemandList;

const MainWrapper = styled.div`
  h2 {
    color: ${props => props.theme.colors.white_main};
    margin-top: 0;
  }
`;

const VideoContainer = styled.div`
  overflow-y: scroll;
  height: ${props => (!props.liveSessions?.length ? '100vh' : '450px')};
`;
