import React, {createRef} from 'react';
import styled from 'styled-components';
import {sideScroll} from '../../../utilities/sideScroll';
import RightArrow from '../../../assets/images/RightArrow';
import LeftArrow from '../../../assets/images/LeftArrow';
import OnDemandVideoCard from '../Ondemand/videoCard';
import LiveCard from '../Live/liveCard';
import {textButton} from '../../../assets/styles/common';
import {getColors} from '../../../assets/styles/colors';
import {logInfo} from '../../../utilities/logger';

const VideoRow = props => {
  const colors = getColors();
  const scrollRef = createRef();
  const windowWidth = window.screen.width > 1280;
  const pageNumber = props.page?.number || 0;

  const scrollLeft = target => {
    sideScroll(target, 'left', 20, 1125, 45);
  };

  const scrollRight = target => {
    sideScroll(target, 'right', 20, 1125, 45);
  };

  const handleScroll = () => {
    const scrollPosition = scrollRef.current.scrollLeft;
    const paginateLimit =
      (scrollRef.current.scrollWidth - scrollRef.current.clientWidth) * 0.75;

    if (
      scrollPosition >= paginateLimit &&
      !props?.page?.refresh &&
      props.setPage
    ) {
      props.setPage({number: pageNumber + 1, refresh: true});
    }
  };

  const mappedVideos = props.videos.map((video, index) => {
    switch (props.type) {
      case 'Live':
        return <LiveCard key={video.uuid + index} videoInfo={video} />;

      case 'Ondemand':
        return <OnDemandVideoCard key={video.uuid + index} videoInfo={video} />;

      default:
        logInfo('[VideoRow][type]: default ', props.type);
        return [];
    }
  });

  return props.videos && !props.videos.length ? (
    <NoVideo>
      <h3>{props.noVideoMessage}</h3>
    </NoVideo>
  ) : windowWidth && props.videos && props.videos.length > 3 ? (
    <VideoScroll>
      <button
        className="scroll-btn"
        style={textButton}
        onClick={() => {
          scrollLeft(scrollRef.current);
        }}>
        <LeftArrow style={{width: 20}} color={colors.grey_6} />
      </button>
      <Ul
        numOfLi={props.videos && props.videos.length}
        cardWidth="350px"
        className="full"
        ref={scrollRef}
        onScroll={handleScroll}>
        {mappedVideos}
      </Ul>
      <button
        className="scroll-btn"
        style={textButton}
        onClick={() => {
          scrollRight(scrollRef.current);
        }}>
        <RightArrow style={{width: 20}} color={colors.grey_6} />
      </button>
    </VideoScroll>
  ) : (
    <Ul
      numOfLi={props.videos && props.videos.length}
      cardWidth="350px"
      className="full"
      style={{marginLeft: '20px'}}
      ref={scrollRef}>
      {mappedVideos}
    </Ul>
  );
};

export default VideoRow;

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-gap: 25px;
  grid-template-columns:
    0px repeat(${props => props.numOfLi}, ${props => props.cardWidth})
    30px;
  grid-template-rows: minmax(200px, 1fr);
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none
  scroll-snap-type: x proximity;
  padding-bottom: 15px;
  margin-bottom: -5px;

  &:before,
  &:after {
    content: "";
    width: 25px;
  }

  &.full {
    grid-column: 1 / -1;
  }
`;

const NoVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  text-align: center;
  color: ${props => props.theme.colors.white_main};
`;

const VideoScroll = styled.div`
  display: flex;

  .scroll-btn {
    max-width: 20px;
    margin: auto 0;
    transition: all 0.5s ease-in-out;
    border-color: none !important;
    box-shadow: none;
    cursor: pointer;

    &:hover {
      transform: scale(1.25);
    }

    & svg {
      margin: 0 5px;
      padding: 0;
    }
  }
`;
