import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {getColors} from '../../../../../assets/styles/colors';

const TagButton = props => {
  const colors = getColors();
  const filters = useSelector(state => state.filters.onDemand.info);
  let active = [...filters.equipment, ...filters.music, ...filters.difficulty];

  const activeColor = active.some(e => e.name === props.tag.name)
    ? colors.teal_main
    : colors.grey_4;

  return (
    <MainContainer
      activeColor={activeColor}
      onClick={() => props.handelFilters(props.category, props.tag)}>
      {props.tag.name}
    </MainContainer>
  );
};

export default TagButton;

const MainContainer = styled.button`
  background-color: ${props => props.activeColor};
  padding: 10px 5px;
  margin-top: 1.25%;
  margin-right: 1.5%;
  border-radius: 4px;
  color: ${props => props.theme.colors.white_main};
  box-shadow: none;
  border: none;
  cursor: pointer;
`;
