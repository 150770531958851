import {Link, useHistory, useLocation} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import HamburgerMenuIcon from '../../../assets/images/HamburgerMenuIcon';
import ProfileIcon from '../../../assets/images/ProfileIcon';
import createStyles from './styles';
import {getColors} from '../../../assets/styles/colors';
import {isSafari} from 'react-device-detect';
import logo from '../../../assets/images/logo.svg';
import profileBigIcon from '../../../assets/images/ProfileBigIcon.svg';
import {setUser} from '../../../redux/actions/user';
import styled from 'styled-components';
import {user} from '../../../utilities/apiCalls';

const SideMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const styles = createStyles();
  const userInfo = useSelector(state => state.user);
  const [showAccount, setShowAccount] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const pathname = location.pathname;

  useEffect(() => {
    setShowMenu(window.innerWidth > 750);
    setShowAccount(pathname.includes('account'));
    window.addEventListener('resize', handelResize);

    return () => window.removeEventListener('resize', handelResize);
  }, [pathname]);

  const handelResize = () => {
    setShowMenu(window.innerWidth > 750);
  };

  const signOut = () => {
    user.invalidate().then(payload => {
      dispatch(setUser({}));
      history.replace('/login');
    });
  };

  return (
    <MainWrapper>
      <Logo>
        <Link to="/video">
          <img
            className="logo-image"
            onClick={() => setShowAccount(false)}
            src={logo}
            alt="Inspire logo"
          />
        </Link>
        {/* TODO Replace this and get it working  */}
        <HamburgerMenuIcon
          className={'mobile-menu'}
          onClick={() => setShowMenu(!showMenu)}
        />
      </Logo>

      {showMenu && (
        <Content>
          <div>
            <LinkContainer onClick={() => setShowAccount(false)}>
              <Link
                to={'/video'}
                style={{...styles.link, opacity: pathname === '/' ? 1 : 0.4}}
                onClick={() => {
                  setShowAccount(false);
                }}>
                Workouts
              </Link>
            </LinkContainer>

            <LinkContainer>
              <Link
                style={{
                  ...styles.link,
                  opacity: pathname === '/account' ? 1 : 0.4
                }}
                onClick={() => {
                  setShowAccount(true);
                }}
                to={'/account'}>
                Account
              </Link>
            </LinkContainer>

            {showAccount && (
              <LinkContainer>
                <Link
                  to={'/account/profile'}
                  style={{
                    ...styles.link,
                    ...styles.subLink,
                    opacity: pathname.includes('/account/profile') ? 1 : 0.4
                  }}>
                  Edit Profile
                </Link>

                {/* <Link
                  to={'/account/subscription'}
                  style={{
                    ...styles.link,
                    ...styles.subLink,
                    opacity: pathname.includes('/account/subscription')
                      ? 1
                      : 0.4
                  }}>
                  Manage Subscription
                </Link> */}
              </LinkContainer>
            )}
          </div>
          <UserContainer>
            <hr className={'hr'} />
            <div className={'profile'}>
              <Link className={'profile-link'} to={'/account/profile'}>
                {isSafari ? (
                  <object
                    aria-label={'profile outline'}
                    data={profileBigIcon}
                    type="image/svg+xml"
                    className={'profile-icon'}
                  />
                ) : (
                  <ProfileIcon
                    color={getColors().grey_6}
                    className={'profile-icon'}
                  />
                )}
                <div className={'profile-name'}>
                  <span>
                    {userInfo.firstName && userInfo.lastName
                      ? `${userInfo.firstName} ${userInfo.lastName}`
                      : ''}
                  </span>
                </div>
              </Link>
              <SignOutButton onClick={() => signOut()}>Sign Out</SignOutButton>
            </div>
          </UserContainer>
        </Content>
      )}
    </MainWrapper>
  );
};

export default SideMenu;

const LinkContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 30px;
`;

const MainWrapper = styled.div`
  width: 250px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(38, 38, 38);

  @media (min-width: 770px) {
    position: fixed;
    left: 0;
    top: 0;
  }

  @media (max-width: 770px) {
    width: 100%;
    height: 100%;
    align-items: flex-start;
  }

  .mobile-menu {
    padding: 0 15px 5px 15px;
    width: 24px;
    height: 24px
    margin-right: 25px;
    
    @media (min-width: 770px) {
      display: none;
    }
  }
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 200px;

  @media (max-width: 770px) {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

const Logo = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-image {
    cursor: pointer;
  }

  @media (max-width: 770px) {
    margin-bottom: 15px;
    justify-content: space-between;

    .logo-image {
      width: 175px;
      margin-left: 10px;
    }

    .menu-icon {
      display: block;
      cursor: pointer;
    }
  }
`;

const UserContainer = styled.div`
  width: 100%;
  height: 75px;
  position: relative;

  .hr {
    color: ${props => props.theme.colors.white_main};
    opacity: 0.2;
    margin: 0;
  }

  .profile {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${props => props.theme.colors.white_main};

    &-icon {
      width: 30px;
      margin-left: 20px;
    }

    &-name {
      display: flex;
      align-items: center;
      margin-left: 10px;
      font-size: 16px;
      max-width: 150px;

      & span {
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;    
      }

      @media (max-width: 770px) {
        max-width: 200px;
      }    
    }

    &-link {
      text-decoration: none;
      color: ${props => props.theme.colors.white_main};
      display: flex;
    }
`;

const SignOutButton = styled.div`
  font-size: 12px;
  opacity: 0.4;
  cursor: pointer;
  position: absolute;
  top: 7px;
  right: 5px;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 770px) {
    position: relative;
    margin-right: 15px;
    top: 0;
    right: 0;
  }
`;
