import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {getColors} from '../../../../assets/styles/colors';
import {setPromoState} from '../../../../redux/actions/registration';
import {promoStates} from '../../../../redux/reducers/registration';
import PromoForm from './PromoForm';

const AddPayment = props => {
  const dispatch = useDispatch();
  const colors = getColors();
  const promoState = useSelector(state => state.registration.promoState);

  return (
    <MainWrapper>
      {promoState === promoStates.visible ? (
        <PromoForm />
      ) : promoState === promoStates.success ? (
        <div className={'card card-added'}>
          <h2>Promo Code Success</h2>
          <p>Your promo code has successfully been added.</p>
        </div>
      ) : (
        <div
          onClick={() => dispatch(setPromoState(promoStates.visible))}
          className={'card'}>
          <h2>Have a promo code?</h2>
          <h3 style={{color: colors.orange_main}}>Add promo code</h3>
        </div>
      )}
    </MainWrapper>
  );
};

export default AddPayment;

const MainWrapper = styled.div`
  margin: 0 auto;

  @media (max-width: 450px) {
    width: 100vw;
  }

  & h2 {
    font-weight: 600;
  }

  .card {
    width: 500px;
    height: 125px;
    margin: 10px 0;
    padding: 20px 0 0 0;
    border-radius: 8px;
    border: solid 1px ${props => props.theme.colors.grey_6};
    background-color: ${props => props.theme.colors.grey_3};
    color: ${props => props.theme.colors.white_main};
    cursor: pointer;

    @media (max-width: 450px) {
      width: 75vw;
      padding: 25px;
      margin: 5px 0;
    }

    &-added {
      background-color: ${props => props.theme.colors.teal_main}1F;
      border-color: ${props => props.theme.colors.teal_main};
    }
  }
`;
