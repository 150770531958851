import React from 'react';
import styled from 'styled-components';
import UniversalButton from '../UniversalButton';

const AccountExists = props => {
  const url = document.location.origin;

  return props.showModal ? (
    <Background>
      <Modal>
        <h1 className={'title'}>Error Creating Account</h1>
        <p className={'body'}>This email is already in use.</p>
        <UniversalButton
          className={'btn-sign'}
          onClick={() => {
            window.location.replace(`${url}/signin`);
          }}
          label={'Sign In'}
        />
      </Modal>
    </Background>
  ) : null;
};

export default AccountExists;

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 0;
  background-color: ${props => props.theme.colors.grey_base}CF;
`;

const Modal = styled.div`
  display: flex;
  border-radius: 2px;
  background-color: ${props => props.theme.colors.grey_4};
  position: absolute;
  width: 550px;
  height: 200px;
  top: calc(50% - 100px);
  left: calc(50% - 275px);
  z-index: 2;
  color: ${props => props.theme.colors.white_main};
  display: flex;
  flex-direction: column;

  @media (max-width: 450px) {
    width: 90vw;
    top: 10vh;
    left: 5vw;
    margin: 0 auto;
    padding: 0;
  }

  .title {
    align-self: start;
    font-size: 1.25rem;
    margin: 10px auto;
  }
  .body {
    align-self: start;
    font-size: 1rem;
    margin: 10px auto;
  }
`;
