import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {user} from '../../utilities/apiCalls';
import {emailRegex} from '../../utilities/regex';
import {textButton} from '../../assets/styles/common';
import {useHistory} from 'react-router-dom';
import eyeShow from '../../assets/images/eye-show.png';
import eyeHide from '../../assets/images/eye-hide.png';
import Error from '../common/Error';
import MessageBox from '../common/MessageBox';
import {setUser} from '../../redux/actions/user';
import UniversalButton from '../common/UniversalButton';
import {useDispatch} from 'react-redux';

const SignInForm = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {register, errors, handleSubmit} = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [signInError, setSignInError] = useState(false);

  const onSubmit = async event => {
    setSignInError(false);
    props.setProcessing(true);
    user.login({email: event.email, password: event.password}).then(payload => {
      if (!payload.error) {
        user.getUserInfo(payload.data.userID).then(userPayload => {
          dispatch(setUser(userPayload.data));
          history.replace('/video');
        });
      } else {
        setSignInError(true);
        props.setProcessing(false);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>Email</label>
        <Error message={errors.email?.message ? errors.email.message : ''} />
      </div>
      <input
        style={errors.email ? props.styles.hasError : props.styles.input}
        name={'email'}
        type={'text'}
        ref={register({
          required: {value: true, message: 'Email is required.'},
          pattern: {value: emailRegex, message: 'Invalid Email'}
        })}
      />

      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>Password</label>
        <Error
          message={errors.password?.message ? errors.password.message : ''}
        />
      </div>
      <div style={{position: 'relative'}}>
        <input
          style={errors.password ? props.styles.hasError : props.styles.input}
          name={'password'}
          type={showPassword ? 'text' : 'password'}
          ref={register({
            required: {value: true, message: 'Password is required.'}
          })}
        />
        <img
          src={showPassword ? eyeShow : eyeHide}
          style={props.styles.icon}
          onClick={() => setShowPassword(!showPassword)}
          alt={'Eye Icon to toggle password visibility.'}
        />
        <p
          style={{...textButton, fontSize: 12, paddingTop: 5}}
          onClick={() => {
            history.push('/reset-password');
          }}
          href={'#'}>
          Forgot Password?
        </p>
      </div>
      <UniversalButton
        processing={props.processing}
        type={'submit'}
        label={'Sign In'}
      />
      <MessageBox
        show={signInError}
        message={'Error Logging in'}
        error={true}
      />
    </form>
  );
};

export default SignInForm;
