import {getColors} from './colors';

const createFormStyles = props => {
  const colors = getColors();

  return {
    hasError: {
      backgroundColor: colors.grey_4,
      fontSize: '1.3em',
      border: `1.5px solid ${colors.red_error}`,
      borderRadius: 5,
      width: '100%',
      margin: '3px 0px 0 0px',
      height: 50,
      color: colors.white_main,
      paddingLeft: 10,
      boxSizing: 'border-box'
    },
    input: {
      fontSize: '1rem',
      width: '100%',
      margin: '3px 0px 0px 0px',
      height: 50,
      borderRadius: 5,
      border: `solid 1px ${colors.white_main}`,
      backgroundColor: colors.grey_4,
      color: colors.white_main,
      paddingLeft: 10,
      boxSizing: 'border-box'
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignContent: 'center',
      marginTop: 10
    },
    button: {
      display: 'block',
      margin: '40px auto 40px auto',
      maxWidth: 200,
      padding: 12,
      maxHeight: 43,
      backgroundColor: props.processing ? colors.grey_4 : colors.red_main,
      width: 300,
      color: colors.white_main,
      fontSize: 16,
      borderRadius: 21.5,
      border: 'none'
    },
    icon: {
      position: 'absolute',
      right: 10,
      top: 20,
      width: 23,
      height: 16,
      borderRadius: '0.3rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box'
    },
    title: {fontSize: 28, marginBottom: 69},
    subTitle: {fontSize: 22},
    createAccount: {textAlign: 'center', cursor: 'pointer'}
  };
};

export default createFormStyles;
