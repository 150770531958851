import React from 'react';
import styled from 'styled-components';

const MessageBox = props => {
  return props.show ? (
    <MainWrapper error={props.error}>
      <p>{props.message}</p>
    </MainWrapper>
  ) : null;
};

export default MessageBox;

const MainWrapper = styled.div`
  border-left: solid 3px
    ${props =>
      props.error
        ? props.theme.colors.red_error
        : props.theme.colors.green_success};
  background-color: ${props => props.theme.colors.grey_disabled};
  height: 40px;
  margin-bottom: 25px;

  & p {
    font-size: 0.9rem;
    padding: 12px 0 0 21px;
  }
`;
