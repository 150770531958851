import styled from 'styled-components';

const Checkboxes = styled.div`
  .checkbox {
    font-family: arial;
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 16px;
  }
  .checkbox input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .checkbox_indicator {
    position: absolute;
    top: 25%;
    left: 0;
    height: 16px;
    width: 16px;
    background: ${props => props.theme.colors.grey_base};
    border: 2px solid ${props => props.theme.colors.white_main};
    border-radius: 0px;
  }

  .checkbox input:checked ~ .checkbox_indicator {
    background: ${props => props.theme.colors.grey_base};
  }

  .checkbox input:disabled ~ .checkbox_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
  }
  .checkbox_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
  }
  .checkbox input:checked ~ .checkbox_indicator:after {
    display: block;
  }
  .checkbox-checkbox .checkbox_indicator:after {
    left: 5px;
    top: 1px;
    width: 4px;
    height: 9px;
    border: solid ${props => props.theme.colors.white_main};
    border-width: 0px 2px 2px 0;
    transform: rotate(45deg);
  }
  .checkbox-checkbox input:disabled ~ .checkbox_indicator:after {
    border-color: ${props => props.theme.colors.grey_disabled};
  }

  #cb {
    margin-top: 13px;
  }

  #cb-text {
    font-size: 14px;
    line-height: 1.29;
  }

  #not-checkd {
    opacity: 0.4;
  }

  #checkd {
    opacity: 1;
  }

  #indicator {
    top: 15%;
  }
`;

export default Checkboxes;
