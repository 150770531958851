import {
  SET_WS_DATA,
  SET_WS_REFRESH,
  SET_LIVE_CLASS_ENDED,
  ADD_LIVE_CLASSES
} from '../actions/typeConstants';
import moment from 'moment';

const defaultState = {
  wsClasses: {},
  apiClasses: {},
  refresh: false,
  liveClassEnded: false
};

const websocket = (state = defaultState, action) => {
  let newState = {...state};
  switch (action.type) {
    case SET_WS_DATA:
      if (!action.data) {
        return state;
      }

      newState.wsClasses = action.data;

      return newState;
    case SET_WS_REFRESH:
      newState.refresh = action.data;
      return newState;
    case SET_LIVE_CLASS_ENDED:
      newState.liveClassEnded = action.data;
      return newState;
    case ADD_LIVE_CLASSES:
      action.data.data.sort((a, b) => {
        a.start_time = moment.utc(a.start_time).format('YYYY-MM-DDTHH:mm:ss');
        a.end_time = moment.utc(a.end_time).format('YYYY-MM-DDTHH:mm:ss');
        b.start_time = moment.utc(b.start_time).format('YYYY-MM-DDTHH:mm:ss');
        b.end_time = moment.utc(b.end_time).format('YYYY-MM-DDTHH:mm:ss');
        return moment(a.start_time).diff(b.start_time);
      });
      newState.apiClasses[action.data.key] = action.data.data;
      return newState;
    default:
      return state;
  }
};

export default websocket;
