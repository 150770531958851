import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import {textButton} from '../../../assets/styles/common';
import UniversalButton from '../../common/UniversalButton';
import {useDispatch, useSelector} from 'react-redux';
import {paymentStates, promoStates} from '../../../redux/reducers/registration';
import {setPaymentState} from '../../../redux/actions/registration';
import {getColors} from '../../../assets/styles/colors';

const SkipModal = props => {
  const colors = getColors();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const promoState = useSelector(state => state.registration.promoState);
  const isTrial = promoState === promoStates.success ? 'subscription' : 'trial';
  const endDate = user?.subscription?.data?.current_period_end
    ? moment
        .unix(user.subscription.data.current_period_end)
        .format('MM-DD-YYYY')
    : moment().add(30, 'day').format('MM-DD-YYYY');

  return props.showSkipModal ? (
    <Background>
      <Modal>
        <p className="title">Are you sure?</p>
        <p>
          {`Your Premium ${isTrial} will end on ${endDate}. If you add a payment
          method now, you won't lose access to your Live & On Demand workouts
          once it expires.`}
        </p>
        <UniversalButton
          style={{margin: 20, fontWeight: 600}}
          label={'ADD PAYMENT'}
          onClick={() => {
            props.setShowSkipModal(false);
            dispatch(setPaymentState(paymentStates.billing));
          }}
        />
        <button
          style={{
            ...textButton,
            paddingBottom: 40,
            paddingTop: 10,
            color: colors.orange_main
          }}
          onClick={() => history.replace('/subscription/created')}>
          Skip without payment
        </button>
      </Modal>
    </Background>
  ) : null;
};

export default SkipModal;

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 0;
  background-color: ${props => props.theme.colors.grey_base}CF;
`;

const Modal = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.colors.grey_4};
  position: absolute;
  width: 640px;
  top: calc(50% - 100px);
  left: calc(50% - 320px);
  z-index: 2;
  color: ${props => props.theme.colors.white_main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 450px) {
    width: 90vw;
    top: 10vh;
    left: 5vw;
    margin: 0 auto;
    padding: 0;
  }

  & p {
    width: 85%;
    font-size: 16px;
    margin: 10px;
  }

  .title {
    font-size: 22px;
    margin-top: 30px;
    // margin-left: 30px;
    line-height: 1.3;
    // align-self: center;
    // text-align: center;
  }
`;
