import {getCookie} from './common';
import {logError} from './logger';
import moment from 'moment';

const axios = require('axios');
const isBeta = window.location.href.includes('beta');

const baseUrl = `https://${isBeta ? 'beta.' : ''}inspirefitness.com`;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] =
  'application/json;charset=UTF-8';

export const reqConfig = {
  isBeta,
  fitness: isBeta
    ? `https://fitness-beta.inspirefitness.com`
    : `https://fitness.inspirefitness.com`,
  auth: `${baseUrl}/api/auth`,
  payments: `${baseUrl}/api/payments`,
  options: {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }
};

export const favorites = {
  get: async userUUID => {
    const url = `${reqConfig.fitness}/spotlight/favorites`;
    const params = {user_uuid: userUUID};
    return await axios
      .get(url, {params})
      .then(response => {
        return {data: response.data, error: ''};
      })
      .catch(e => {
        logError('[favorites][get]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: [], error: e};
      });
  },
  add: async uuid => {
    const url = `${reqConfig.fitness}/user/favorite-workouts`;
    const body = {'workout-uuid': uuid};
    return await axios
      .post(url, body, reqConfig.options)
      .then(response => {
        return {data: response.data, error: ''};
      })
      .catch(e => {
        logError('[favorites][add]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: {}, error: e};
      });
  },
  delete: async uuid => {
    const url = `${reqConfig.fitness}/user/favorite-workouts`;
    const body = {'workout-uuid': uuid};
    return await axios
      .delete(url, {...reqConfig.options, data: body})
      .then(response => {
        return {data: response.data, error: ''};
      })
      .catch(e => {
        logError('[favorites][delete]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: {}, error: e};
      });
  }
};

export const bookmarks = {
  getVideo: async () => {
    const url = `${reqConfig.fitness}/bookmarks`;
    return await axios
      .get(url, reqConfig.options)
      .then(response => {
        return {data: response.data, error: ''};
      })
      .catch(e => {
        logError('[bookmarks][getVideo]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: [], error: e};
      });
  },
  addVideoBookmark: async uuid => {
    const url = `${reqConfig.fitness}/bookmark/class/${uuid}`;
    return await axios
      .post(url, {}, reqConfig.options)
      .then(response => {
        return {data: response.data.data, error: ''};
      })
      .catch(e => {
        logError('[bookmarks][addVideoBookmark]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: {}, error: e};
      });
  },
  removeVideoBookmark: async uuid => {
    const url = `${reqConfig.fitness}/bookmark/class/${uuid}`;

    return await axios
      .delete(url, reqConfig.options)
      .then(response => {
        return {data: response.data.data, error: ''};
      })
      .catch(e => {
        logError('[bookmarks][removeVideoBookmark]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: {}, error: e};
      });
  }
};

export const sessions = {
  get: {
    query: async query => {
      const url = `${reqConfig.fitness}/session${query}`;

      return await axios
        .get(url, reqConfig.options)
        .then(response => {
          return {data: response.data, error: ''};
        })
        .catch(e => {
          console.log('[sessions][get][query] Error: ', e);
          return {data: [], error: e};
        });
    },
    params: async params => {
      const url = `${reqConfig.fitness}/session`;

      return await axios
        .get(url, {params})
        .then(response => {
          return {data: response.data, error: ''};
        })
        .catch(e => {
          console.log('[sessions][get][params] Error: ', e);
          return {data: [], error: e};
        });
    },
    getOne: async uuid => {
      const url = `${reqConfig.fitness}/session/${uuid}`;

      return await axios
        .get(url, reqConfig.options)
        .then(response => {
          return {data: response.data, error: ''};
        })
        .catch(e => {
          console.log('[sessions][get][getOne] Error: ', e);
          return {data: [], error: e};
        });
    },
    liveSessions: async ({date}) => {
      const url = `${reqConfig.fitness}/session`;
      const params = {
        sort_by: 'start_time',
        min_start_date: moment(date).startOf('day').format(),
        max_start_date: moment(date).add(1, 'day').startOf('day').format(),
        recording_statuses: 'imminent,encoding,ready',
        session_statuses: 'Accessible',
        live: true
      };

      return await axios
        .get(url, {params})
        .then(response => {
          const classes = response.data ?? [];
          return {data: classes, error: ''};
        })
        .catch(e => {
          console.log('[sessions][get][liveSessions] Error: ', e.response);
          return {data: [], error: e};
        });
    }
  }
};

export const trainers = {
  get: async () => {
    const url = `${reqConfig.fitness}/instructors?archived=false`;
    return await axios
      .get(url, reqConfig.options)
      .then(response => {
        return {data: response.data, error: ''};
      })
      .catch(e => {
        logError('[trainers][getAll]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: [], error: e};
      });
  }
};

export const user = {
  createUser: async body => {
    const url = `${reqConfig.auth}/users/register?registrationType=new`;
    body.email = body.email.trim();
    body.subscriptionType = 3;

    return await axios
      .post(url, body, reqConfig.options)
      .then(() => {
        return {data: true, error: ''};
      })
      .catch(e => {
        logError('[user][createUser]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: false, error: e};
      });
  },
  getUserInfo: async userID => {
    const url = `${reqConfig.fitness}/auth/users/${userID}?with-subscription-info=true`;
    return await axios
      .get(url, reqConfig.options)
      .then(response => {
        return {data: response.data.data, error: ''};
      })
      .catch(e => {
        logError('[user][getUserInfo]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: {}, error: e};
      });
  },
  validate: async () => {
    const url = `${reqConfig.fitness}/auth/validate`;
    return await axios
      .post(url, {}, reqConfig.options)
      .then(response => {
        return {data: response.data, error: ''};
      })
      .catch(e => {
        logError('[user][post][validate]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: {}, error: e};
      });
  },
  invalidate: async () => {
    const url = `${reqConfig.fitness}/auth/invalidate`;
    return await axios
      .post(url, {}, reqConfig.options)
      .then(() => {
        return true;
      })
      .catch(e => {
        logError('[user][post][invalidate]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return false;
      });
  },
  login: async info => {
    const url = `${reqConfig.fitness}/auth/authenticate`;
    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `secret ${info.password}`,
        email: info.email
      }
    };
    return await axios
      .post(url, {}, config)
      .then(response => {
        return {data: response.data, error: ''};
      })
      .catch(e => {
        logError('[user][post][login]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: {}, error: e};
      });
  },
  resetPassword: async email => {
    const url = `${reqConfig.fitness}/auth/reset-password`;
    const config = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    return await axios
      .post(url, {email}, config)
      .then(() => {
        return true;
      })
      .catch(e => {
        logError('[user][resetPassword]', e);
        return false;
      });
  },
  updatePassword: async body => {
    const url = `${reqConfig.fitness}/auth/update-password`;

    return await axios
      .post(url, body, reqConfig.options)
      .then(() => {
        return true;
      })
      .catch(e => {
        logError('[user][changePassword]', e);
        return false;
      });
  },
  updateUser: async (userID, body) => {
    const url = `${reqConfig.auth}/users/${userID}`;

    return await axios
      .put(url, body, reqConfig.options)
      .then(response => {
        return true;
      })
      .catch(e => {
        logError('[user][updateUser]', e);
        return false;
      });
  },

  createTrial: async () => {
    const url = `${reqConfig.payments}/subscription/user`;

    const accountTypes = await axios.get(
      `${reqConfig.fitness}/payment/subscription/types`,
      reqConfig.options
    );

    const type = accountTypes.data.data.filter(sub => {
      if (sub.name.toLowerCase().includes('trial')) {
        return sub.id;
      } else {
        return '';
      }
    });

    const body = {subTypeID: type[0].id};

    return await axios
      .post(url, body, reqConfig.options)
      .then(() => {
        return {data: true, error: ''};
      })
      .catch(e => {
        return {data: false, error: e};
      });
  },
  cancelSubscription: async () => {
    const url = `${reqConfig.fitness}/payment/cancel-subscription`;

    return await axios
      .post(url, {}, reqConfig.options)
      .then(() => {
        return {data: true, error: ''};
      })
      .catch(e => {
        logError('%c [user][cancelSubscription]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: false, error: e};
      });
  },
  submitSerialPromo: async body => {
    const url = `${reqConfig.fitness}/payment/claim-promo-code-or-serial-number`;

    return await axios
      .post(url, body, reqConfig.options)
      .then(() => {
        return {data: true, error: ''};
      })
      .catch(e => {
        logError('[user][submitSerialPromo]', {
          response: e.response,
          userID: getCookie('x-user')
        });
        return {data: false, error: e};
      });
  }
};

export const analytics = {
  send: async (info, uuid) => {
    const url = `${reqConfig.fitness}/class-view/${uuid}`;
    const axiosConfig = {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'x-auth': 'mobile'
      }
    };

    return await axios
      .post(url, info, axiosConfig)
      .then(response => {
        return {data: response.data, error: ''};
      })
      .catch(e => {
        logError('[analytics][send]', e.response);
        return {data: {}, error: e};
      });
  }
};

export const metadataV2 = {
  get: async () => {
    const url = `${reqConfig.fitness}/v2/metadata`;
    return await axios
      .get(url, reqConfig.options)
      .then(response => {
        return {data: response.data, error: ''};
      })
      .catch(e => {
        console.log('[metadataV2][get] Error: ', e.response);
        return {data: [], error: e.response};
      });
  }
};
