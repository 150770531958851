import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true // enable logs
};
// ReactPixel.init('yourPixelIdGoesHere', advancedMatching, options);

ReactPixel.init('797191163988481', {}, options);
const tracking = true;

const actions = {
  PageView: () => {
    if (tracking) {
      ReactPixel.pageView();
    }
  }
};

export let FBPixel = actions;
