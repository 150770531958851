import {
  SET_REFRESH,
  SET_TRAINERS,
  SET_METADATA
} from '../actions/typeConstants';

const config = (state = {trainers: [], refresh: true}, action) => {
  const newState = {...state};
  switch (action.type) {
    case SET_TRAINERS:
      if (!action.data) {
        return state;
      }
      newState.trainers = action.data;
      return newState;

    case SET_METADATA:
      if (!action.data) {
        return state;
      }
      newState.metadata = action.data;
      return newState;

    case SET_REFRESH:
      newState.refresh = action.data;
      return newState;

    default:
      return state;
  }
};

export default config;
