// Config
export const SET_TRAINERS = 'SET_TRAINERS';
export const SET_REFRESH = 'SET_REFRESH';
export const SET_METADATA = 'SET_METADATA';

// Filters
export const SET_SELECTED_LIVE_DATE = 'SET_SELECTED_LIVE_DATE';
export const SET_LIVE_SESSIONS = 'SET_LIVE_SESSIONS';
export const SET_ONDEMAND_FILTERS = 'SET_ONDEMAND_FILTERS';
export const SET_ONDEMAND_RANGE = 'SET_ONDEMAND_RANGE';
export const SET_ONDEMAND_TRAINER = 'SET_ONDEMAND_TRAINER';
export const SET_BOOKMARKED = 'SET_BOOKMARKED';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const UPDATE_ONDEMAND_QUERY = 'UPDATE_ONDEMAND_QUERY';
export const SET_REFRESH_SESSIONS = 'SET_REFRESH_SESSIONS';

// User
export const SET_USER = 'SET_USER';
export const UPDATE_SUBSCRIPTION_INFO = 'UPDATE_SUBSCRIPTION_INFO';

// Websocket
export const SET_WS_DATA = 'SET_WS_DATA';
export const SET_WS_REFRESH = 'SET_WS_REFRESH';
export const SET_LIVE_CLASS_ENDED = 'SET_LIVE_CLASS_ENDED';
export const ADD_LIVE_CLASSES = 'ADD_LIVE_CLASSES';

// Modals
export const TOGGLE_CLASS_MODAL = 'TOGGLE_CLASS_MODAL';

// Registration
export const SET_PAYMENT_STATE = 'SET_PAYMENT_STATE';
export const SET_PROMO_STATE = 'SET_PROMO_STATE';
export const SET_BILLING_INFO = 'SET_BILLING_INFO';
