import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import {textButton} from '../../../assets/styles/common';
import {setUser} from '../../../redux/actions/user';
import {paymentStates} from '../../../redux/reducers/registration';
import {user} from '../../../utilities/apiCalls';
import Footer from '../../common/Footer';
import TopLogo from '../../common/TopLogo';
import UniversalButton from '../../common/UniversalButton';
import AddPayment from './addPayment';
import AddPromo from './addPromo';
import InfoCards from './InfoCards';
import SkipModal from './SkipModal';
import {getCookie} from '../../../utilities/common';
import {FBPixel} from '../../../utilities/facebookPixel';
import {getColors} from '../../../assets/styles/colors';

const Payment = () => {
  const colors = getColors();
  const history = useHistory();
  const dispatch = useDispatch();
  const paymentState = useSelector(state => state.registration.paymentState);
  const userInfo = useSelector(state => state.user);
  const [showSkipModal, setShowSkipModal] = useState(false);

  const userEndDate = userInfo?.subscription?.data?.current_period_end;
  const hasSerialPromo =
    userEndDate &&
    moment
      .unix(userInfo.subscription.data.current_period_end)
      .isAfter(moment().add(40, 'days'));

  const endDate = userEndDate
    ? moment
        .unix(userInfo.subscription.data.current_period_end)
        .format('MM-DD-YYYY')
    : 'XX-XX-XXXX';

  const title = hasSerialPromo
    ? 'Auto-renew your Premium subscription'
    : 'Your first 30 days of Premium are on us';
  const body = hasSerialPromo
    ? `Your Premium subscription will end on ${endDate}. Add a payment method now and we'll auto-renew your subscription, so you don't have to hassle with it later. Don't worry, you can always cancel your auto-renew before your card is charged.`
    : 'Your new account comes with 30 days of free access to our Premium subscription. With Premium, you get access to Live and On Demand video workouts by our very own trainers.';

  useEffect(() => {
    FBPixel.PageView();
  }, []);

  useEffect(() => {
    const userID = getCookie('x-user');
    user.getUserInfo(userID).then(payload => dispatch(setUser(payload.data)));
  }, [dispatch]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <SkipModal
        showSkipModal={showSkipModal}
        setShowSkipModal={setShowSkipModal}
      />
      <TopLogo style={{margin: '20px 0 0 0', width: 300}} />
      <MainWrapper>
        <h2>{title}</h2>
        <p>{body}</p>
        {!hasSerialPromo && (
          <p>
            Add a payment method now and we'll auto-renew your Premium
            subscription at the end of your free trial.
          </p>
        )}
        <InfoCards />
        <AddPayment />
        {!hasSerialPromo && <AddPromo />}
        {paymentState === paymentStates.success ? (
          <UniversalButton
            style={{fontSize: 18}}
            onClick={() => history.replace('/subscription/created')}
            label={'Continue'}
          />
        ) : (
          <button
            style={{
              ...textButton,
              color: colors.orange_main,
              margin: 30,
              fontSize: 18
            }}
            onClick={() => setShowSkipModal(true)}>
            Skip without payment
          </button>
        )}
      </MainWrapper>
      <Footer />
    </div>
  );
};

export default Payment;

const MainWrapper = styled.div`
  width: 660px;
  text-align: center;
  margin: 0 auto;
  padding: 25px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.white_main};

  @media (max-width: 450px) {
    width: 90vw;
    margin: 0;
  }

  & h2 {
    font-weight: 500;
  }

  .terms-container {
    margin-top: 50px;
  }

  .terms-link {
    color: ${props => props.theme.colors.white_main};
    line-height: 1.3;
    font-size: 14px;
    text-decoration: none;
  }
`;
