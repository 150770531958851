import * as React from 'react';

function CreditCardIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={22}
      viewBox="0 0 32 22"
      {...props}>
      <g
        stroke="#888"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M31.547 19.293c0 1.344-1.109 2.444-2.462 2.444H2.872c-1.354 0-2.461-1.1-2.461-2.444V2.85C.41 1.508 1.518.407 2.872.407h26.213c1.353 0 2.462 1.1 2.462 2.444v16.442z" />
        <path d="M28.265 16.848a1.64 1.64 0 01-1.641 1.63h-6.186a1.64 1.64 0 01-1.641-1.63v-3.226c0-.897.738-1.63 1.64-1.63h6.187c.902 0 1.64.733 1.64 1.63v3.226zM.411 4.437h31.136M.411 8.511h31.136" />
      </g>
    </svg>
  );
}

export default CreditCardIcon;
