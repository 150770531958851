import React from 'react';
import styled from 'styled-components';
import InstructorImage from './InstructorImages';

const InstructorInfo = props => {
  const info = props.videoInfo;
  const instructorNames =
    info && info?.instructors?.length
      ? info.instructors.map(item => item.first_name).join(' • ')
      : null;
  const mappedInstructorImages = info?.instructors?.map((instructor, index) => {
    return (
      <InstructorImage
        key={instructor.uuid}
        instructor={instructor}
        index={index}
      />
    );
  });

  return (
    <MainWrapper>
      {mappedInstructorImages}
      <div className="names">{instructorNames}</div>
    </MainWrapper>
  );
};

export default InstructorInfo;

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;

  .names {
    color: #fff;
    font-size: 12px;
    margin-left: 10px;
  }
`;
