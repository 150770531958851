import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import VideoRow from '../common/VideoRow';
import Calendar from './calendar';
import moment from 'moment';
import {sessions} from '../../../utilities/apiCalls';
import {setLiveSesssions} from '../../../redux/actions/filters';

const Live = () => {
  const dispatch = useDispatch();
  const liveSessions = useSelector(state => state.filters.live.sessions);
  const currentDateRef = useRef(moment().format());
  const [currentDateIndex, setCurrentDateIndex] = useState(0);
  const setDateFunc = (index, dates) => {
    setCurrentDateIndex(index);
    currentDateRef.current = moment(dates[index]).format();
  };

  const getLiveSessions = date => {
    sessions.get.liveSessions(date).then(res => {
      if (!res.error) {
        dispatch(setLiveSesssions(res.data));
      }
    });
  };

  useEffect(() => {
    const _30SECONDS = 30000;
    const refreshInterval = setInterval(() => {
      getLiveSessions({date: currentDateRef.current});
    }, _30SECONDS);
    return () => clearInterval(refreshInterval);
  });

  const handleOnSelectDate = date => {
    getLiveSessions({date});
  };

  return (
    <Section>
      <h2 className="font-weight-light">Live Classes</h2>
      <Calendar
        height={window.screen.height}
        width={window.screen.width}
        showDaysAfterCurrent={21}
        showDaysBeforeCurrent={0}
        onSelectDate={handleOnSelectDate}
        setDateFunc={setDateFunc}
        currentDateIndex={currentDateIndex}
      />
      <VideoRow
        type={'Live'}
        videos={liveSessions}
        noVideoMessage={'No Live Videos for Selected Date.'}
      />
    </Section>
  );
};

export default Live;

const Section = styled.div`
  max-width: 95vw;
  min-width: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (max-width: 425px) {
    margin-left: 10px;
  }

  & h2 {
    color: ${props => props.theme.colors.white_main};
    text-align: left;
  }
`;
