import React from 'react';
import {getColors} from '../../assets/styles/colors';
import Loader from './Loader';

const UniversalButton = props => {
  const colors = getColors();
  const processing = props.processing ? props.processing : false;

  const btnStyle = {
    outline: 'none',
    cursor: processing ? 'not-allowed' : 'pointer',
    display: 'block',
    margin: '40px auto 40px auto',
    padding: 12,
    maxHeight: 43,
    backgroundColor: processing ? colors.grey_disabled : colors.red_main,
    color: colors.white_main,
    fontSize: 16,
    borderRadius: 21.5,
    border: 'none',
    minWidth: 150,
    fontWeight: 600
  };

  return (
    <button
      onClick={props.onClick}
      style={{...btnStyle, ...props.style}}
      className={props.className}
      type={props.type}
      disabled={processing || props.disabled}>
      {processing ? <Loader size={20} /> : props.label.toUpperCase()}
    </button>
  );
};

export default UniversalButton;
