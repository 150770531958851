import React from 'react';
import Bookmark from '../../common/Bookmark';
import Details from './Details';
import Thumbnail from '../../common/Thumbnail';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

const OnDemandVideoCard = props => {
  const history = useHistory();

  const cardPressed = async () => {
    history.push(`/video/${window.btoa(props.videoInfo.uuid)}`);
  };

  return (
    <MainWrapper onClick={cardPressed}>
      <Bookmark
        videoInfo={props.videoInfo}
        style={{
          position: 'absolute',
          top: 0,
          right: 20,
          width: 24,
          height: 24,
          zIndex: 10
        }}
      />
      <Details videoInfo={props.videoInfo} />
      <Thumbnail videoInfo={props.videoInfo} />
    </MainWrapper>
  );
};

export default OnDemandVideoCard;

const MainWrapper = styled.div`
  width: 350px;
  height: 200px;
  margin-right: 3%;
  margin-bottom: 3%;
  position: relative;
  cursor: pointer;
`;
