import * as React from 'react';

function WeightIcon(props) {
  const color = props.color ? props.color : '#fffff';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 255 255 0"
          />
        </filter>
      </defs>
      <g
        filter="url(#prefix__a)"
        transform="translate(0 -3)"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path
          d="M8.373 6.882l3.137 1.811m-5.047-1.12l5.227 3.018m-4.071 4.527l-3.136-1.81M9.316 14.5l-5.227-3.018m4.89-2.133l-2.103 3.642M15.53 11A7.53 7.53 0 01.47 11C.47 6.843 3.843 3.47 8 3.47s7.53 3.372 7.53 7.53z"
          stroke={color}
        />
      </g>
    </svg>
  );
}

export default WeightIcon;
