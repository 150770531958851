import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Footer from '../../common/Footer';
import SerialNumberForm from './SerialNumberForm';
import TopLogo from '../../common/TopLogo';
import SuccessModal from './SuccessModal';
import {getCookie} from '../../../utilities/common';
import {user} from '../../../utilities/apiCalls';
import {useDispatch} from 'react-redux';
import {setUser} from '../../../redux/actions/user';
import {FBPixel} from '../../../utilities/facebookPixel';

const SerialNumber = () => {
  const dispatch = useDispatch();
  const [showSerialSuccessModal, setShowSerialSuccessModal] = useState(false);

  useEffect(() => {
    FBPixel.PageView();
  }, []);

  useEffect(() => {
    const userID = getCookie('x-user');
    user.getUserInfo(userID).then(payload => dispatch(setUser(payload.data)));
  }, [dispatch]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <SuccessModal showSerialSuccessModal={showSerialSuccessModal} />
      <TopLogo style={{margin: '20px 0 0 0', width: 300}} />
      <MainWrapper>
        <h2>Your equipment</h2>
        <p>
          Please enter your serial number below. This should be located on the
          Serial Number sticker on your equipment.
        </p>
        <SerialNumberForm
          setShowSerialSuccessModal={setShowSerialSuccessModal}
        />
        <Footer />
      </MainWrapper>
    </div>
  );
};

export default SerialNumber;

const MainWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 25px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.white_main};

  & h2 {
    font-weight: 500;
  }

  .terms-container {
    margin-top: 50px;
  }

  .terms-link {
    color: ${props => props.theme.colors.white_main};
    line-height: 1.3;
    font-size: 14px;
    text-decoration: none;
  }
`;
