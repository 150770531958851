import React from 'react';
import styled from 'styled-components';
import CreditCardIcon from '../../../../assets/images/CreditCardIcon';
import PaymentForm from './PaymentForm';
import CardForm from './CardForm';
import {useDispatch, useSelector} from 'react-redux';
import {paymentStates} from '../../../../redux/reducers/registration';
import {setPaymentState} from '../../../../redux/actions/registration';

const AddPayment = props => {
  const dispatch = useDispatch();
  const paymentState = useSelector(state => state.registration.paymentState);

  return (
    <MainWrapper>
      {paymentState !== paymentStates.success && <h2>Payment (optional)</h2>}
      {paymentState === paymentStates.billing ? (
        <PaymentForm />
      ) : paymentState === paymentStates.card ? (
        <CardForm />
      ) : paymentState === paymentStates.success ? (
        <div className={'card'}>
          <h2>Payment Method Added</h2>
          <p>Your subscription will auto-renew </p>
        </div>
      ) : (
        <div
          onClick={() => dispatch(setPaymentState(paymentStates.billing))}
          className={'card'}>
          <CreditCardIcon />
          <h2>Add Payment Method</h2>
        </div>
      )}
    </MainWrapper>
  );
};

export default AddPayment;

const MainWrapper = styled.div`
  margin: 0 auto;

  @media (max-width: 450px) {
    width: 100vw;
  }

  & h2 {
    font-weight: 400;
  }

  .card {
    width: 500px;
    height: 125px;
    margin: 10px 0;
    padding: 20px 0 0 0;
    border-radius: 8px;
    border: solid 1px ${props => props.theme.colors.grey_6};
    background-color: ${props => props.theme.colors.grey_3};
    color: ${props => props.theme.colors.white_main};
    cursor: pointer;

    @media (max-width: 450px) {
      width: 75vw;
      padding: 25px;
      margin: 5px 0;
    }
  }
`;
