import React from 'react';
import styled from 'styled-components';

const PageContainer = props => {
  return (
    <MainWrapper style={{...props.style}} className={props.className}>
      {props.children}
    </MainWrapper>
  );
};

export default PageContainer;

const MainWrapper = styled.div`
  margin: 0 0 0 250px;
  padding: 50px;
  max-width: 95%;

  @media (max-width: 770px) {
    margin: 0;
  }
`;
