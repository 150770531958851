import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import Error from '../../common/Error';
import MessageBox from '../../common/MessageBox';
import RadioButtons from '../../../assets/styles/RadioStyles';
import Checkboxes from '../../../assets/styles/CheckboxStyles';
import {useSelector} from 'react-redux';
import {user} from '../../../utilities/apiCalls';
import eyeShow from '../../../assets/images/eye-show.png';
import eyeHide from '../../../assets/images/eye-hide.png';
import {useHistory} from 'react-router-dom';
import UniversalButton from '../../common/UniversalButton';
import MaskedInput from 'react-text-mask';
import {getColors} from '../../../assets/styles/colors';

const AccountForm = props => {
  const colors = getColors();
  const history = useHistory();
  const userInfo = useSelector(state => state.user);
  const {register, errors, handleSubmit, setValue} = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [gender, setGender] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [dateOfBirthMsg, dateOfBirthSubmitMsg] = useState('');

  useEffect(() => {
    const userDateOfBirth = userInfo.dateOfBirth ? userInfo.dateOfBirth : '';

    setValue('firstName', userInfo.firstName);
    setValue('lastName', userInfo.lastName);
    setDateOfBirth(userDateOfBirth);
    setValue('userName', userInfo.userName);
    setGender(userInfo.gender);
  }, [userInfo, setValue]);

  const onSubmit = async event => {
    setSaveError(false);
    props.setProcessing(true);
    setSubmitted(false);
    user
      .createUser({
        email: event.email,
        password: event.password,
        passwordConfirmation: event.password,
        firstName: event.firstName,
        lastName: event.lastName,
        dateOfBirth: dateOfBirth,
        userName: event.userName,
        gender: gender
      })
      .then(payload => {
        setSubmitted(true);
        setSaveError(payload.data);
        props.setProcessing(false);
        if (!payload.data) {
          if (payload.error.response.status === 409) {
            props.setAccountExists(true);
          }
        } else {
          history.push(
            `/subscription/verification/${window.btoa(event.email)}`
          );
        }
      });
  };

  const handelDateOfBirthChange = event => {
    setDateOfBirth(event.target.value);
    if (dateOfBirth.length === 0 && dateOfBirthMsg === 'invalid') {
      dateOfBirthSubmitMsg('');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
      <p style={props.styles.subTitle}>Login</p>
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>Email</label>
        <Error message={errors.email?.message ? errors.email.message : ''} />
      </div>
      <input
        style={errors.email ? props.styles.hasError : props.styles.input}
        name={'email'}
        type={'text'}
        ref={register({
          required: {value: true, message: 'Email is required.'}
        })}
      />
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>Password</label>
        <Error
          message={errors.password?.message ? errors.password.message : ''}
        />
      </div>
      <div style={{position: 'relative'}}>
        <input
          style={errors.password ? props.styles.hasError : props.styles.input}
          name={'password'}
          type={showPassword ? 'text' : 'password'}
          ref={register({
            required: {value: true, message: 'Password is required.'},
            minLength: {
              value: 8,
              message: 'Needs to be at least 8 characters.'
            }
          })}
        />
        <img
          src={showPassword ? eyeShow : eyeHide}
          style={props.styles.icon}
          onClick={() => setShowPassword(!showPassword)}
          alt={'Eye Icon to toggle password visibility.'}
        />
      </div>
      <p style={props.styles.subTitle}>Profile</p>
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>First Name</label>
        <Error
          message={errors.firstName?.message ? errors.firstName.message : ''}
        />
      </div>
      <input
        style={errors.firstName ? props.styles.hasError : props.styles.input}
        name={'firstName'}
        type={'text'}
        ref={register({
          required: {value: true, message: 'First Name is required.'}
        })}
      />
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>Last Name</label>
        <Error
          message={errors.lastName?.message ? errors.lastName.message : ''}
        />
      </div>
      <input
        style={errors.lastName ? props.styles.hasError : props.styles.input}
        name={'lastName'}
        type={'text'}
        ref={register({
          required: {value: true, message: 'Last Name is required.'}
        })}
      />
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>
          Date of Birth (MM-DD-YYYY) (optional)
        </label>
      </div>
      <MaskedInput
        style={errors.dateOfBirth ? props.styles.hasError : props.styles.input}
        value={dateOfBirth}
        onChange={handelDateOfBirthChange}
        type="text"
        mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      />

      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>Display Name (optional)</label>
        <Error
          message={errors.userName?.message ? errors.userName.message : ''}
        />
      </div>
      <input
        style={errors.userName ? props.styles.hasError : props.styles.input}
        name={'userName'}
        type={'text'}
        ref={register({
          required: {value: false, message: ''}
        })}
      />
      <RadioButtons>
        <p style={{textAlign: 'left'}}>Gender (optional)</p>
        <label className="radio radio-radio">
          Male
          <input
            type="radio"
            name="gender"
            value="male"
            checked={gender === 'male'}
            onChange={() => {}}
            onClick={value => setGender(value.target.value)}
          />
          <div className="radio_indicator" />
        </label>
        <label className="radio radio-radio">
          Female
          <input
            type="radio"
            name="gender"
            value="female"
            checked={gender === 'female'}
            onChange={() => {}}
            onClick={value => setGender(value.target.value)}
          />
          <div className="radio_indicator" />
        </label>
        <label className="radio radio-radio">
          Other
          <input
            type="radio"
            name="gender"
            value="other"
            checked={gender === 'other'}
            onChange={() => {}}
            onClick={value => setGender(value.target.value)}
          />
          <div className="radio_indicator" />
        </label>
      </RadioButtons>
      <Checkboxes className="terms-container">
        <label className="checkbox checkbox-checkbox">
          <a
            className="terms-link"
            href="https://inspirefitness.com/privacy-policy/"
            target="_blank"
            rel="noreferrer">
            I have read and accept the Terms, Privacy & Equipment Safety
            Policies.
          </a>
          <input
            type="checkbox"
            defaultChecked={policy}
            onClick={() => setPolicy(!policy)}
          />
          <div className="checkbox_indicator" />
        </label>
      </Checkboxes>
      <UniversalButton
        disabled={props.processing || !policy}
        type={'submit'}
        style={{
          backgroundColor:
            props.processing || !policy ? colors.grey_disabled : colors.red_main
        }}
        label={'Continue'}
      />
      <MessageBox
        show={submitted}
        message={saveError ? 'Account created.' : 'Error creating account.'}
        error={!saveError}
      />
    </form>
  );
};

export default AccountForm;
