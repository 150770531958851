import React from 'react';
import styled from 'styled-components';

const InstructorImage = ({instructor, index}) => {
  return (
    <MainWrapper index={index}>
      <img
        src={instructor.image_url}
        className="avatar"
        alt={`${instructor.first_name} Avatar`}></img>
    </MainWrapper>
  );
};

export default InstructorImage;

const MainWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  overflow: hidden;
  margin-left: ${props => (props.index !== 0 ? '-20px' : '0px')};

  .avatar {
    width: 38px;
    height: 38px;
  }
`;
