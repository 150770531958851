import {TOGGLE_CLASS_MODAL} from '../actions/typeConstants';

const modals = (
  state = {
    classes: {
      filters: false
    }
  },
  action
) => {
  const newState = {...state};
  switch (action.type) {
    case TOGGLE_CLASS_MODAL:
      if (!action.data) {
        return state;
      }
      newState.classes[action.data] = !newState.classes[action.data];
      return newState;

    default:
      return state;
  }
};

export default modals;
