import React, {useEffect, useState} from 'react';
import {sessions} from '../../../utilities/apiCalls';
import {useSelector} from 'react-redux';
import VideoRow from '../common/VideoRow';
import Loader from '../../common/Loader';
import {getColors} from '../../../assets/styles/colors';

const VideoList = props => {
  const reduxQuery = useSelector(state => state.filters.onDemand.query);
  const filteredEquipment = useSelector(
    state => state.filters.onDemand.info.equipment
  );
  const userInfo = useSelector(state => state.user);
  const refreshSessions = useSelector(state => state.filters.refresh);
  const [state, setState] = useState({loading: true, videos: []});
  const [page, setPage] = useState({number: 0, refresh: true});

  useEffect(() => {
    const getClasses = () => {
      const findFilter = filteredEquipment.filter(
        item => item.name === props.row.name
      );
      const query = reduxQuery + `all_equipment=${props.row.name}&`;
      const pagination = `page=${page.number}&limit=${10}&`;
      const url =
        props.row.name === 'My Favorites'
          ? `?session_statuses=Accessible&vod_statuses=Approved&is_published=true&sort_by=c.publish_date,desc&favorited_user_uuids=${userInfo.uuid}&${pagination}`
          : query + pagination;

      if (!!findFilter.length || !filteredEquipment.length) {
        sessions.get.query(url).then(payload => {
          const haveThumbnails = payload.data.filter(
            item => item.thumbnail_url
          );
          setPage({...page, refresh: false});
          setState({
            videos: [...state.videos, ...haveThumbnails],
            loading: false
          });
        });
      } else {
        setPage({...page, refresh: false});
        setState({
          videos: [],
          loading: false
        });
      }
    };

    if (page.refresh) {
      getClasses();
    }
  }, [
    filteredEquipment,
    page,
    props.row.name,
    reduxQuery,
    state.videos,
    userInfo.uuid
  ]);

  useEffect(() => {
    setState({videos: [], loading: true});
    setPage({number: 0, refresh: true});
  }, [reduxQuery, filteredEquipment, refreshSessions]);

  return state.loading ? (
    <div
      style={{
        margin: '25px 0',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Loader size={50} color={getColors().grey_disabled} />
    </div>
  ) : !!state.videos.length ? (
    <div>
      <p
        style={{
          textAlign: 'left',
          fontWeight: 'bold',
          color: getColors().white_main,
          margin: '0px 0px 0px 45px'
        }}>
        {props.row.name}
      </p>
      <VideoRow
        type={'Ondemand'}
        videos={state.videos}
        page={page}
        setPage={setPage}
      />
    </div>
  ) : null;
};

export default VideoList;
