import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import LeftArrow from '../../assets/images/LeftArrow';
import {textButton} from '../../assets/styles/common';

const BackButton = props => {
  const history = useHistory();

  return (
    <MainWrapper className={'back-button'}>
      <button style={textButton} onClick={() => history.push(props.routeTo)}>
        <LeftArrow className={'left-arrow'} /> {props.label}
      </button>
    </MainWrapper>
  );
};

export default BackButton;

const MainWrapper = styled.div`
  margin-left: 250px;
  padding: 10px;

  @media (max-width: 770px) {
    margin: 0;
  }

  .left-arrow {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
`;
