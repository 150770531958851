import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import VideoCard from '../classes/Live/liveCard';

const LiveList = props => {
  const liveSessions = useSelector(state => state.filters.live.sessions);
  const filterOutCurrentSessions = liveSessions?.filter(
    item => item.uuid !== props.videoInfo.uuid
  );

  return !!filterOutCurrentSessions.length ? (
    <MainWrapper>
      <h2>Upcoming Live Classes</h2>
      <VideoContainer sessions={filterOutCurrentSessions}>
        {filterOutCurrentSessions.map(video => (
          <VideoCard key={video.uuid} videoInfo={video} />
        ))}
      </VideoContainer>
    </MainWrapper>
  ) : null;
};

export default LiveList;

const MainWrapper = styled.div`
  h2 {
    color: ${props => props.theme.colors.white_main};
    margin-top: 0;
  }
`;

const VideoContainer = styled.div`
  overflow-y: scroll;
  height: ${props => (props.sessions.length <= 1 ? '250px' : '450px')};
`;
