import * as React from 'react';

function PlayIcon(props) {
  const color = props.color ? props.color : '#ffffff';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 255 255 0"
          />
        </filter>
      </defs>
      <g
        stroke={color}
        filter="url(#prefix__a)"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <path d="M15.68 8A7.68 7.68 0 11.32 8a7.68 7.68 0 0115.36 0z" />
        <path d="M11.708 7.997L6.147 4.786v6.421z" />
      </g>
    </svg>
  );
}

export default PlayIcon;
