import styled from 'styled-components';

const RadioButtons = styled.div`
  display: flex;
  flex-direction: column;

  .radio {
    font-family: arial;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    position: relative;
    margin-bottom: 5px;
    padding-top: 0px;
    cursor: pointer;
    font-size: 16px;
  }
  .radio input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .radio_indicator {
    height: 14px;
    width: 14px;
    background: ${props => props.theme.colors.grey_base};
    border: 3px solid ${props => props.theme.colors.teal_main};
  }

  .radio input:checked ~ .radio_indicator {
    background: ${props => props.theme.colors.grey_base};
    border-color: ${props => props.theme.colors.teal_main};
  }

  .radio input:disabled ~ .radio_indicator {
    background: ${props => props.theme.colors.grey_base};
    opacity: 0.6;
    pointer-events: none;
  }
  .radio_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
  }
  .radio input:checked ~ .radio_indicator:after {
    display: block;
  }
  .radio-radio .radio_indicator {
    border-radius: 50%;
  }

  .radio-radio .radio_indicator:after {
    left: 85px;
    top: 5px;
    height: 10.3px;
    width: 10.3px;
    border-radius: 50%;
    background: ${props => props.theme.colors.teal_main};
  }
  .radio-radio input:disabled ~ .radio_indicator:after {
    background: ${props => props.theme.colors.grey_base};
  }
`;

export default RadioButtons;
