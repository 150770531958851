import React from 'react';

const BookmarkIcon = props => {
  return (
    <svg height={props.height} width={props.width} viewBox="0 0 100 100">
      {props.videoPage && (
        <line x1="0" x2="100" y1="0" y2="0" stroke={'#fff'} strokeWidth="15" />
      )}
      <line x1="0" x2="0" y1="0" y2="100" stroke={'#fff'} strokeWidth="15" />
      <line
        x1="100"
        x2="100"
        y1="0"
        y2="100"
        stroke={'#fff'}
        strokeWidth="15"
      />
      <line x1="0" x2="50" y1="100" y2="50" stroke={'#fff'} strokeWidth="8" />
      <line x1="50" x2="100" y1="50" y2="100" stroke={'#fff'} strokeWidth="8" />

      {props.bookmarked ? (
        <>
          <rect
            x="0"
            y="0"
            width="150"
            height="50"
            fill={'#fff'}
            strokeWidth="1"
            stroke={'#fff'}
          />
          <polygon
            points="0,50 50,50 0,100"
            fill={'#fff'}
            stroke={'#fff'}
            strokeWidth="1"
          />
          <polygon
            points="100,50 50,50 100,100"
            fill={'#fff'}
            stroke={'#fff'}
            strokeWidth="1"
          />
        </>
      ) : null}
    </svg>
  );
};

export default BookmarkIcon;
