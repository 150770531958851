import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {sessions} from '../../utilities/apiCalls';
import PageContainer from '../classes/common/PageContainer';
import Loader from '../common/Loader';
import SideMenu from '../Nav/SideMenu';
import Details from './Details';
import LiveList from './LiveList';
import OndemandList from './OndemandList';
import VideoPlayer from './VideoPlayer';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {setLiveSesssions} from '../../redux/actions/filters';

const VideoPage = props => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [videoInfo, setVideoInfo] = useState({});
  const classID = window.atob(props.computedMatch.params.classID);
  const refresh = useSelector(state => state.filters.refresh);

  useEffect(() => {
    const getSession = () => {
      sessions.get.getOne(classID).then(payload => {
        if (!payload.error) {
          setVideoInfo(payload.data);
          setLoading(false);
        }
      });
    };
    const getLiveSessions = date => {
      sessions.get.liveSessions(date).then(res => {
        if (!res.error) {
          dispatch(setLiveSesssions(res.data));
        }
      });
    };
    getLiveSessions(moment().format());
    getSession();
    let interval;
    if (videoInfo.stream_url || videoInfo.vod_status === 'Ready for Approval') {
      interval = setInterval(() => {
        getSession();
      }, 30000);
    }
    return () => (interval ? clearInterval(interval) : null);
  }, [classID, dispatch, refresh, videoInfo.stream_url, videoInfo.vod_status]);

  return loading ? (
    <LoaderContainer>
      <Loader size={50} color={'#ff653f'} />
    </LoaderContainer>
  ) : (
    <MainWrapper>
      <SideMenu />
      <PageContainer className={'container'}>
        <div className={'video-container'}>
          <VideoPlayer videoInfo={videoInfo} />
          <Details videoInfo={videoInfo} />
        </div>
        <div className={'lists'}>
          <LiveList videoInfo={videoInfo} />
          <OndemandList videoInfo={videoInfo} />
        </div>
      </PageContainer>
    </MainWrapper>
  );
};

export default VideoPage;

const LoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainWrapper = styled.div`
  background-color: rgb(27, 28, 28);

  .container {
    display: flex;
    flex-direction: column;

    @media (min-width: 1420px) {
      flex-direction: row;
    }
  }

  .lists {
    display: flex;
    flex-direction: column;

    @media (max-width: 1420px) {
      flex-direction: row;
      justify-content: space-around;
      margin-top: 50px;
    }
    @media (max-width: 1070px) {
      flex-direction: column;
      margin-top: 50px;
    }
  }

  .video-container {
    padding-right: 50px;
    flex-grow: 100;

    @media (max-width: 1420px) {
      padding-right: 0;
    }
  }
`;
