import React from 'react';
import {useSelector} from 'react-redux';
import {allEquipmentObjects} from '../../../../../utilities/common';
import TagButton from './TagButton';

const TagBased = props => {
  const metadata = useSelector(state => state.config.metadata);
  const fitnessLevels = metadata?.fitness_level || [];
  const fitnessLevelsObj = fitnessLevels?.length
    ? fitnessLevels.map(item => {
        return {name: item};
      })
    : [];
  const music = metadata?.music || [];
  const musicObj = music?.length
    ? music.map(item => {
        return {name: item};
      })
    : [];
  const equipment = allEquipmentObjects;

  const category =
    props.category === 'fitness level'
      ? fitnessLevelsObj
      : props.category === 'music'
      ? musicObj
      : props.category === 'equipment'
      ? equipment
      : [];

  return (
    <div>
      <p style={{marginTop: '25px'}}>{props.category.toUpperCase()}</p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}>
        {category.map((item, index) => (
          <TagButton
            key={item + index}
            tag={item}
            category={props.category}
            handelFilters={props.handelFilters}
          />
        ))}
      </div>
    </div>
  );
};

export default TagBased;
