import React from 'react';
import styled from 'styled-components';

const Favorites = props => {
  return (
    <MainWrapper className="favorites" bookmarked={props.bookmarked}>
      <p>Favorited</p>
      <input
        className="react-switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
        checked={props.bookmarked?.length}
        onChange={() => props.handelFilters('bookmark')}
      />
      <label className="react-switch-label" htmlFor={`react-switch-new`}>
        <span className={`react-switch-button`} />
      </label>
    </MainWrapper>
  );
};

export default Favorites;

const MainWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 0 0;

  .react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 50px;
    height: 25px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;
    background-image: linear-gradient(
      to right,
      ${props => props.theme.colors.grey_3},
      ${props => props.theme.colors.grey_4}
    );
  }

  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 45px;
    transition: 0.2s;

    background: ${props =>
      props.bookmarked?.length
        ? props.theme.colors.orange_main
        : props.theme.colors.grey_6};
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  .react-switch-label:active .react-switch-button {
    width: 60px;
  }
`;
