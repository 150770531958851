import * as React from 'react';

function ActivityIcon(props) {
  const color = props.color ? props.color : '#ffffff';

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 18 20" {...props}>
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 255 255 0"
          />
        </filter>
      </defs>
      <g
        filter="url(#prefix__a)"
        transform="translate(0 -99)"
        fill="none"
        fillRule="evenodd">
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M.086 108.393A8 8 0 0015.965 107a8 8 0 00-14.208-5.045"
        />
        <path fill={color} d="M.598 104.329l2.691-1.414L0 101.349z" />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M.598 104.329l2.691-1.414L0 101.349zm7.428-2.422V107l3.22 3.22"
        />
      </g>
    </svg>
  );
}

export default ActivityIcon;
