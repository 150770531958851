/* eslint-disable */
import React, {useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import CountDownClock from '../classes/common/CountDown';
import LiveClassEnded from '../classes/common/LiveClassEnded';
import moment from 'moment';

const VideoPlayer = props => {
  const videoPlayer = useRef();
  const [started, setStarted] = useState(false);
  const [progress, setProgress] = useState(0);
  const progressRef = useRef(0);
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    startTime: '',
    endTime: ''
  });
  const url =
    props.videoInfo.stream_url && props.videoInfo.vod_status === 'Needs Edits'
      ? props.videoInfo.stream_url
      : props.videoInfo?.video?.url || null;
  const showPlayer =
    props.videoInfo.stream_url ||
    props.videoInfo.vod_status === 'Ready for Approval'
      ? props.videoInfo.recording_status === 'encoding' &&
        moment().isSameOrAfter(moment(props.videoInfo.start_time))
      : true;

  useEffect(() => {
    if (playerState.isPlaying) {
      progressRef.current =
        progressRef.current + (progress - playerState.startTime);
    }
  }, [playerState.isPlaying, playerState.startTime, progress]);

  useEffect(() => {
    if (props.videoInfo.vod_status === 'Ready for Approval' && showPlayer) {
      const startTimeDiffForFakeLive = moment().diff(
        moment(props.videoInfo.start_time),
        's'
      );
      startTimeDiffForFakeLive > 0 &&
        videoPlayer?.current?.seekTo(startTimeDiffForFakeLive, 'seconds');
    }
  }, [props.videoInfo.start_time, showPlayer, props.videoInfo.vod_status]);

  return (
    <MainWrapper>
      {showPlayer ? (
        <ReactPlayer
          key={`${url} - ${props.videoInfo.recording_status}`}
          ref={videoPlayer}
          url={url}
          controls={true}
          width={'100%'}
          height={'100%'}
          playing={
            props.videoInfo.stream_url ||
            props.videoInfo.vod_status === 'Ready for Approval'
              ? true
              : false
          }
          onProgress={currentProgress => {
            setProgress(currentProgress.playedSeconds);
          }}
          onSeek={() =>
            setPlayerState({
              ...playerState,
              isPlaying: false,
              startTime: videoPlayer.current.getCurrentTime()
            })
          }
          onPlay={() => {
            setStarted(true);
            setPlayerState({
              ...playerState,
              isPlaying: true,
              startTime: videoPlayer.current.getCurrentTime()
            });
          }}
          onPause={() => {
            setPlayerState({
              ...playerState,
              isPlaying: false,
              endTime: videoPlayer.current.getCurrentTime()
            });
          }}
        />
      ) : (
        props.videoInfo.recording_status !== 'done' && (
          <div className={'placeholder'}>
            <CountDownClock videoInfo={props.videoInfo} />
          </div>
        )
      )}
      {(props.videoInfo.vod_status === 'Needs Edits' ||
        props.videoInfo.vod_status === 'Ready for Approval') &&
      props.videoInfo.recording_status === 'done' ? (
        <div className={'placeholder'}>
          <LiveClassEnded videoInfo={props.videoInfo} />
        </div>
      ) : null}
    </MainWrapper>
  );
};

export default VideoPlayer;

const MainWrapper = styled.div`
  background-color: rgb(27, 28, 28);
  width: 100%;

  .placeholder {
    height: 350px;
    justify-content: center;
    align-items: center;

    @media (max-width: 749px) {
      width: 80vw;
    }
  }
`;
