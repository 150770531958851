import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import TrainerCard from './TrainerCard';
import styled from 'styled-components';

const Trainers = props => {
  const trainers = useSelector(state => state.config.trainers);
  const [showAll, setShowAll] = useState(false);

  return (
    <MainWrapper>
      <p>TRAINERS</p>
      <div
        className={
          showAll ? 'instructor-list instructor-list--all' : 'instructor-list'
        }>
        {trainers.map(trainer => (
          <TrainerCard
            key={trainer.uuid}
            trainer={trainer}
            handelFilters={props.handelFilters}
            trainers={props.trainers}
          />
        ))}
      </div>
      <span onClick={() => setShowAll(!showAll)} className="instructor-all">
        {showAll ? 'View Less' : 'View All'}
      </span>
    </MainWrapper>
  );
};

export default Trainers;

const MainWrapper = styled.section`
  .instructor {
    & span {
      margin: auto 0 auto 10px;
    }

    &-all {
      cursor: pointer;
      padding: 10px 0 10px calc(50% - 50px);
      color: ${props => props.theme.colors.orange_main};
      width: 50px;
      margin: 0 auto;
    }

    &-list {
      overflow: hidden;
      max-height: 280px;
      margin-left: -20px;

      &--all {
        max-height: initial;
      }
    }
  }
`;
