import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import Error from '../../common/Error';
import MessageBox from '../../common/MessageBox';
import RadioButtons from '../../../assets/styles/RadioStyles';
import {useSelector} from 'react-redux';
import {user} from '../../../utilities/apiCalls';
import UniversalButton from '../../common/UniversalButton';

const SignInForm = props => {
  const userInfo = useSelector(state => state.user);
  const {register, errors, handleSubmit, setValue} = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [gender, setGender] = useState('');

  useEffect(() => {
    setValue('firstName', userInfo.firstName);
    setValue('lastName', userInfo.lastName);
    setValue('dateOfBirth', userInfo.dateOfBirth);
    setValue('userName', userInfo.userName);
    setGender(userInfo.gender);
  }, [userInfo, setValue]);

  const onSubmit = async event => {
    setSaveError(false);
    props.setProcessing(true);
    setSubmitted(false);
    user
      .updateUser(userInfo.id, {
        firstName: event.firstName,
        lastName: event.lastName,
        dateOfBirth: event.dateOfBirth,
        userName: event.userName,
        gender: gender
      })
      .then(payload => {
        setSubmitted(true);
        setSaveError(payload);
        props.setProcessing(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>First Name</label>
        <Error
          message={errors.firstName?.message ? errors.firstName.message : ''}
        />
      </div>
      <input
        style={errors.firstName ? props.styles.hasError : props.styles.input}
        name={'firstName'}
        type={'text'}
        ref={register({
          required: {value: false, message: ''}
        })}
      />
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>Last Name</label>
        <Error
          message={errors.lastName?.message ? errors.lastName.message : ''}
        />
      </div>
      <input
        style={errors.lastName ? props.styles.hasError : props.styles.input}
        name={'lastName'}
        type={'text'}
        ref={register({
          required: {value: false, message: ''}
        })}
      />
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>Date of Birth (MM-DD-YYYY)</label>
        <Error
          message={
            errors.dateOfBirth?.message ? errors.dateOfBirth.message : ''
          }
        />
      </div>
      <input
        style={errors.dateOfBirth ? props.styles.hasError : props.styles.input}
        name={'dateOfBirth'}
        type={'text'}
        ref={register({
          required: {value: false, message: ''}
        })}
      />
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>Display Name</label>
        <Error
          message={errors.userName?.message ? errors.userName.message : ''}
        />
      </div>
      <input
        style={errors.userName ? props.styles.hasError : props.styles.input}
        name={'userName'}
        type={'text'}
        ref={register({
          required: {value: false, message: ''}
        })}
      />
      <RadioButtons>
        <p>Gender</p>
        <label className="radio radio-radio">
          Male
          <input
            type="radio"
            name="gender"
            value="male"
            checked={gender === 'male'}
            onChange={() => {}}
            onClick={value => setGender(value.target.value)}
          />
          <div className="radio_indicator" />
        </label>
        <label className="radio radio-radio">
          Female
          <input
            type="radio"
            name="gender"
            value="female"
            checked={gender === 'female'}
            onChange={() => {}}
            onClick={value => setGender(value.target.value)}
          />
          <div className="radio_indicator" />
        </label>
        <label className="radio radio-radio">
          Other
          <input
            type="radio"
            name="gender"
            value="other"
            checked={gender === 'other'}
            onChange={() => {}}
            onClick={value => setGender(value.target.value)}
          />
          <div className="radio_indicator" />
        </label>
      </RadioButtons>

      <UniversalButton
        processing={props.processing}
        type={'submit'}
        label={'Save Changes'}
      />
      <MessageBox
        show={submitted}
        message={
          saveError ? 'Changes have been saved.' : 'Error saving changes.'
        }
        error={!saveError}
      />
    </form>
  );
};

export default SignInForm;
