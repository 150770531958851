import {
  SET_SELECTED_LIVE_DATE,
  SET_LIVE_SESSIONS,
  SET_ONDEMAND_RANGE,
  UPDATE_ONDEMAND_QUERY,
  SET_ONDEMAND_TRAINER,
  SET_BOOKMARKED,
  CLEAR_FILTERS,
  SET_ONDEMAND_FILTERS,
  SET_REFRESH_SESSIONS
} from '../actions/typeConstants';
import {createActiveFilters, idCheck} from '../../utilities/common';
import moment from 'moment';

const defaultState = {
  onDemand: {
    info: {
      duration: [0, 120],
      trainers: [],
      equipment: [],
      music: [],
      difficulty: [],
      bookmarked: []
    },
    activeFilters: [],
    query:
      '?session_statuses=Accessible&vod_statuses=Approved&is_published=true&sort_by=c.publish_date,desc&'
  },
  live: {
    selectedLiveDate: moment().format('YYYY-MM-DD'),
    sessions: []
  },
  refresh: true
};

const filters = (state = JSON.parse(JSON.stringify(defaultState)), action) => {
  const newState = {...state};
  switch (action.type) {
    case SET_SELECTED_LIVE_DATE:
      if (!action.data) {
        return state;
      }
      newState.live.selectedLiveDate = action.data;
      return newState;

    case SET_LIVE_SESSIONS:
      if (!action.data) {
        return state;
      }
      newState.live.sessions = action.data;
      return newState;

    case SET_ONDEMAND_FILTERS:
      if (!action.data) {
        return state;
      }
      newState.onDemand.info = JSON.parse(JSON.stringify(action.data));
      return newState;

    case SET_ONDEMAND_RANGE:
      if (!action.data) {
        return state;
      }
      newState.onDemand.info.duration = action.data;
      return newState;

    case SET_ONDEMAND_TRAINER:
      if (!action.data) {
        return state;
      }
      newState.onDemand.info.trainers = idCheck(
        newState.onDemand.info.trainers,
        action.data,
        true
      );

      return newState;
    case SET_BOOKMARKED:
      newState.onDemand.info.bookmarked = !newState.onDemand.info.bookmarked;
      return newState;

    case CLEAR_FILTERS:
      newState.onDemand = JSON.parse(JSON.stringify(defaultState.onDemand));
      return newState;

    case UPDATE_ONDEMAND_QUERY:
      let query =
        '?session_statuses=Accessible&vod_statuses=Approved&is_published=true&sort_by=c.publish_date,desc&';
      const minDuration = state.onDemand.info.duration[0] * 60;
      const trainer = state.onDemand.info.trainers.map(item => item.uuid);
      const music = state.onDemand.info.music.map(item => item.name);
      const difficulty = state.onDemand.info.difficulty.map(item => item.name);

      query += `min_duration=${
        minDuration === 600 ? 0 : minDuration
      }&max_duration=${state.onDemand.info.duration[1] * 60}&`;

      if (state.onDemand.info.bookmarked?.length) {
        query += `favorited_user_uuids=${newState.onDemand.info.bookmarked}&`;
      }

      if (music.length) {
        query += `music=${music}&`;
      }
      if (difficulty.length) {
        query += `levels=${difficulty}&`;
      }
      if (trainer.length) {
        query += `instructor_uuids=${trainer}&`;
      }

      newState.onDemand.query = query;
      const createdActiveFilters = createActiveFilters(newState.onDemand.info);
      newState.onDemand.activeFilters = createdActiveFilters;
      return newState;
    case SET_REFRESH_SESSIONS:
      newState.refresh = !newState.refresh;
      return newState;
    default:
      return state;
  }
};

export default filters;
