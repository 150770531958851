import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import SideMenu from '../Nav/SideMenu';
import {setRefresh} from '../../redux/actions/config';
import OverView from './overview';

const Account = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRefresh(true));
  }, [dispatch]);

  return (
    <MainWrapper>
      <SideMenu />
      <OverView />
    </MainWrapper>
  );
};

export default Account;

const MainWrapper = styled.div`
  background-color: ${props => props.theme.colors.grey_base};

  margin-left: 250px;

  @media (max-width: 770px) {
    margin-left: 0;
  }

  @media (min-width: 771) {
    margin-left: 0;
  }

  > * {
    grid-column: 2 / -2;
  }

  .font-weight-light {
    font-weight: lighter;
    margin-left: 20px;
  }

  .classes {
    color: ${props => props.theme.colors.white_main};
    margin: 45px 0px 0px 30px;
    font-weight: 500;
  }

  .on-demand-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    max-height: 200px;
    margin: 0px 0px 20px 10px;
    padding: 7px;
    color: ${props => props.theme.colors.white_main};
    width: 95%;
    border-bottom: solid 3px rgb(38, 38, 38);
  }

  .on-demand-header-container {
    display: flex;
    align-items: center;
  }

  .filter {
    max-height: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
  }

  .filters {
    &-icon {
      width: 20px;
    }

    &-btn {
      background-color: rgb(27, 28, 28);
      border: none;
      cursor: pointer;
      padding-left: 10px;
      padding-top: 5px;
    }
  }

  .workout-type {
    margin: 40px 0px 10px 35px;
    color: ${props => props.theme.colors.white_main};
  }
`;
