/* eslint-disable */

import React, {useEffect, useState} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {getColors} from '../../../assets/styles/colors';

const CountDownClock = props => {
  const [time, setTime] = useState(moment());
  const instructorNames =
    props.videoInfo && props.videoInfo.instructors
      ? props.videoInfo.instructors
          .map(item => `${item.first_name} ${item.last_name}`)
          .join(', ')
      : ['N/A'];
  const isAfterStartTime = moment().isAfter(moment(props.videoInfo.start_time));
  const displayTime = isAfterStartTime
    ? 'a moment'
    : moment(moment(props.videoInfo.start_time).diff(moment())).format('mm:ss');

  useEffect(() => {
    const interval = setInterval(() => tick(), 1000);

    return () => clearInterval(interval);
  }, []);

  const tick = () => setTime(moment());

  return (
    <MainWrapper>
      <p className="text">Thanks for joining!</p>
      <p className="text">
        {`${instructorNames}'s class starts in ${displayTime}`}
      </p>
    </MainWrapper>
  );
};

export default CountDownClock;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(https://s3-us-west-1.amazonaws.com/inspire-studios-assets/instructor-images/inspire_logo_black_globe.png);
  background-color: ${getColors().grey_4};
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 500px;

  .text {
    font-size: 22px;
    color: ${getColors().white_main};
  }
`;
