import {getColors} from './colors';

export const textButton = {
  border: 'none',
  backgroundColor: 'transparent',
  color: getColors().white_main,
  margin: 0,
  padding: 0,
  cursor: 'pointer',
  outline: 'none'
};
