import * as React from 'react';
import {useSelector} from 'react-redux';

function FilterIcon(props) {
  const activeFilters = useSelector(
    state => state.filters.onDemand.activeFilters
  );
  const color = activeFilters?.length ? '#FF653F' : '#fff';

  return (
    <svg width={18} height={17} viewBox={'0 0 18 17'} {...props}>
      <path
        fill={color}
        fillRule={'evenodd'}
        d={
          'M16.829 2.076h-5.54A2.643 2.643 0 008.722.02c-1.25 0-2.298.88-2.567 2.055H.615a.592.592 0 100 1.187h5.54a2.64 2.64 0 002.567 2.054c1.25 0 2.297-.88 2.567-2.054h5.54a.593.593 0 000-1.187zM8.72 4.13a1.46 1.46 0 01-1.454-1.463c0-.805.652-1.46 1.454-1.46.778 0 1.41.617 1.449 1.388a.451.451 0 000 .146A1.457 1.457 0 018.721 4.13zm8.108 9.213h-1.234a2.643 2.643 0 00-2.567-2.054c-1.25 0-2.299.88-2.567 2.054H.616a.592.592 0 000 1.187h9.845a2.643 2.643 0 002.567 2.054c1.25 0 2.297-.88 2.566-2.054h1.235a.592.592 0 000-1.187zm-3.8 2.057a1.46 1.46 0 01-1.455-1.463c0-.805.653-1.46 1.455-1.46a1.46 1.46 0 011.456 1.46 1.46 1.46 0 01-1.456 1.463zm3.8-7.689H6.984a2.643 2.643 0 00-2.567-2.054c-1.25 0-2.298.88-2.567 2.054H.616a.592.592 0 000 1.187h1.233a2.643 2.643 0 002.567 2.054c1.25 0 2.297-.88 2.567-2.054h9.846a.592.592 0 000-1.187zM4.417 9.765A1.46 1.46 0 012.96 8.302c0-.805.653-1.46 1.455-1.46.803 0 1.455.655 1.455 1.46a1.46 1.46 0 01-1.455 1.463z'
        }
      />
    </svg>
  );
}

export default FilterIcon;
