import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import ElapsedClock from '../../common/ElapsedClock';

const Details = props => {
  const info = props.videoInfo;
  const encoding = info.recording_status === 'encoding';
  const startTime = moment(info.start_time);
  const category = info.category;
  const equipment = info?.equipment?.length
    ? [...info.equipment].join(', ')
    : info?.equipment_groups?.length
    ? [...info.equipment_groups].join(', ')
    : 'N/A';
  const duration = info?.video?.duration
    ? Math.ceil(info.video.duration / 60)
    : info.expected_duration / 60;
  const music = info?.music?.join(', ');
  const muscles = info?.muscles?.length ? info.muscles.join(' • ') : '';

  return (
    <MainWrapper>
      <p className="text-name-category">{`${info.name} - ${category}`}</p>
      <p className="text-equipment-duration-music">
        {`${equipment} • ${duration} min • ${music}`}
      </p>
      <p className="text-muscles">{muscles}</p>
      {encoding && moment().isSameOrAfter(startTime) ? (
        <ElapsedClock startTime={info.start_time} />
      ) : (
        <p className="startTime">{startTime.format('ddd l h:mm A')}</p>
      )}
    </MainWrapper>
  );
};

export default Details;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 10;
  color: ${props => props.theme.colors.white_main};
  position: absolute;
  bottom: 5%;
  left: 2.5%;

  & p {
    margin: 1px;
    align-text: left;
  }

  .text {
    &-name-category {
      font-size: 20px;
      font-weight: bold;
    }
    &-equipment-duration-music {
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 300px;
    }
    &-muscles {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  .startTime {
    font-size: 0.7em;
    color: ${props => props.theme.colors.orange_main};
  }

  .joinClass {
    position: absolute;
    bottom: 5px;
    right: 10px;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    border-radius: 7.5px;
    z-index: 10;
    background-color: ${props => props.theme.colors.orange_main};
  }
`;
