import React from 'react';
import styled from 'styled-components';
import {isSafari} from 'react-device-detect';
import ProfileIcon from '../../../assets/images/ProfileIcon';
import WeightIcon from '../../../assets/images/WeightIcon';
import ActivityIcon from '../../../assets/images/ActivityIcon';
import QuickStartIcon from '../../../assets/images/QuickStartIcon';
import PlayIcon from '../../../assets/images/PlayIcon';
import StarIcon from '../../../assets/images/StarIcon';
import starIcon from '../../../assets/images/StarIcon.svg';
import profileIcon from '../../../assets/images/ProfileIcon.svg';
import profileBigIcon from '../../../assets/images/ProfileBigIcon.svg';
import weightIcon from '../../../assets/images/WeightIcon.svg';
import quickStartIcon from '../../../assets/images/QuickStartIcon.svg';
import activityIcon from '../../../assets/images/ActivityIcon.svg';
import playIcon from '../../../assets/images/PlayIcon.svg';
import {getColors} from '../../../assets/styles/colors';

const InfoCards = () => {
  const colors = getColors();

  return (
    <MainWrapper>
      <Card className={'basic'}>
        {isSafari ? (
          <object
            aria-label={'profile outline'}
            data={profileBigIcon}
            type="image/svg+xml"
            className={'icon-big'}
          />
        ) : (
          <ProfileIcon color={colors.grey_6} className={'icon-big'} />
        )}
        <h2>INSPIRE FITNESS ACCOUNT</h2>
        <IconText>
          {isSafari ? (
            <object
              aria-label={'dumbbell outline'}
              data={weightIcon}
              type="image/svg+xml"
              className={'icon'}
            />
          ) : (
            <WeightIcon color={colors.teal_main} className={'icon'} />
          )}
          <p>Written Strength Workouts</p>
        </IconText>
        <IconText>
          {isSafari ? (
            <object
              aria-label={'dumbbell outline'}
              data={weightIcon}
              type="image/svg+xml"
              className={'icon'}
            />
          ) : (
            <WeightIcon color={colors.teal_main} className={'icon'} />
          )}
          <p>Exercise Tutorials </p>
        </IconText>
        <IconText>
          {isSafari ? (
            <object
              aria-label={'quickstart outline'}
              data={quickStartIcon}
              type="image/svg+xml"
              className={'icon'}
            />
          ) : (
            <QuickStartIcon color={colors.teal_main} className={'icon'} />
          )}
          <p>Indoor Cycle Quickstart</p>
        </IconText>
        <IconText>
          {isSafari ? (
            <object
              aria-label={'activity outline'}
              data={activityIcon}
              type="image/svg+xml"
              className={'icon'}
            />
          ) : (
            <ActivityIcon color={colors.teal_main} className={'icon'} />
          )}
          <p>Workout activity Tracker</p>
        </IconText>
      </Card>
      <Card className={'premium'}>
        {isSafari ? (
          <object
            aria-label={'star outline'}
            data={starIcon}
            type="image/svg+xml"
            className={'icon-big'}
          />
        ) : (
          <StarIcon className={'icon-big'} />
        )}
        <h2>PREMIUM SUBSCRIPTION</h2>
        <p className={'cost'}>$9.99/month</p>
        <IconText>
          {isSafari ? (
            <object
              aria-label={'profile outline'}
              data={profileIcon}
              type="image/svg+xml"
              className={'icon'}
            />
          ) : (
            <ProfileIcon color={colors.teal_main} className={'icon'} />
          )}
          <p>Inspire Fitness Account access</p>
        </IconText>
        <IconText>
          {isSafari ? (
            <object
              aria-label={'play button outline'}
              data={playIcon}
              type="image/svg+xml"
              className={'icon'}
            />
          ) : (
            <PlayIcon color={colors.teal_main} className={'icon'} />
          )}
          <p>Live & On Demand Workouts and Programs</p>
        </IconText>
        <ul>
          <li>Strength Training</li>
          <li>Yoga</li>
          <li>Indoor Cycle</li>
          <li>Bodyweight</li>
          <li>Rower</li>
          <li>Strider</li>
          <li>Cross Training</li>
        </ul>
      </Card>
    </MainWrapper>
  );
};

export default InfoCards;

const MainWrapper = styled.div`
  display: flex;

  .premium {
    height: 470px;
    width: 340px;
    background-color: ${props => props.theme.colors.teal_main}1F;
    border: 1px solid ${props => props.theme.colors.teal_main};

    @media (max-width: 450px) {
      height: 425px;
      width: 75vw;
      padding: 25px;
      margin: 5px 0;
    }
  }

  .basic {
    height: 320px;
    padding-top: 100px;
    margin-top: 43.5px;
    color: ${props => props.theme.colors.grey_6};

    & h2 {
      font-weight: 400;
    }

    @media (max-width: 450px) {
      height: 225px;
      width: 75vw;
      padding: 25px;
      margin: 5px 0;
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const IconText = styled.div`
  display: flex;
  margin: 10px 0;

  & p {
    margin: 0;
    width: 225px;
  }

  & img {
    margin-right: 15px;
    margin-left: -15px;
    padding: 0;
  }
`;

const Card = styled.div`
  background-color: ${props => props.theme.colors.grey_3};
  border-radius: 8px;
  height: 395px;
  padding: 25px;
  margin: 10px;
  text-align: left;

  & ul {
    width: 125px;

    & li {
      margin: 10px 0;
    }
  }

  & h2 {
    margin: 20px 0 10px 0;
  }

  .icon {
    width: 16px;
    margin-right: 10px;

    &-big {
      width: 34px;
    }
  }

  .cost {
    color: ${props => props.theme.colors.grey_6};
    font-weight: 600;
    margin: 0 0 20px 0;
  }
`;
