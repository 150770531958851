import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Link, useHistory} from 'react-router-dom';
import {useRouteMatch} from 'react-router-dom';

const NoMatch = () => {
  const history = useHistory();
  const match = useRouteMatch('/');

  useEffect(() => {
    if (match) {
      history.push('/video');
    }
  }, [match, history]);

  return (
    <MainWrapper>
      <img
        className="logo"
        src="https://s3-us-west-1.amazonaws.com/inspirefitweb/wp-content/2019/11/11094441/white_logo.png"
        alt="Inspire logo"
      />
      <section>
        <h1>This page doesn't exist!</h1>
        <p>Sorry, the page you were looking for could not be found.</p>
        <p>
          Go back to{' '}
          <Link className="link" to="/video">
            Workout
          </Link>{' '}
          page.
        </p>
      </section>
    </MainWrapper>
  );
};

export default NoMatch;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${props => props.theme.colors.white_main};
  margin-top: 25px;

  & h2 {
    font-weight: 500;
  }

  .link {
    color: ${props => props.theme.colors.orange_main};
  }

  .logo {
    margin: 0 auto;
  }
`;
