import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {getColors} from '../../../assets/styles/colors';
import {textButton} from '../../../assets/styles/common';

const SuccessModal = props => {
  const history = useHistory();

  return props.showSerialSuccessModal ? (
    <Background>
      <Modal>
        <p className="title">Enjoy your Premium subscription!</p>
        <p>
          Your serial number indicates that your equipment comes with 12 months
          of access to our Premium subscription, with Live and On Demand
          workouts.
        </p>

        <button
          style={{
            ...textButton,
            paddingBottom: 30,
            paddingTop: 20,
            color: getColors().orange_main
          }}
          onClick={() => history.push('/subscription/payment')}>
          Close
        </button>
      </Modal>
    </Background>
  ) : null;
};

export default SuccessModal;

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 0;
  background-color: ${props => props.theme.colors.grey_base}CF;
`;

const Modal = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.colors.grey_4};
  position: absolute;
  width: 500px;
  top: calc(50% - 100px);
  left: calc(50% - 250px);
  z-index: 2;
  color: ${props => props.theme.colors.white_main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  & p {
    width: 70%;
    font-size: 16px;
    margin: 10px;
  }

  @media (max-width: 450px) {
    height: 100%;
    max-width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
  }

  .title {
    font-size: 22px;
    margin-top: 30px;
    // margin-left: 30px;
    line-height: 1.3;
    // align-self: center;
    // text-align: center;
  }

  .btn {
    margin: 30px auto 30px auto;
    padding: 12px;
    cursor: pointer;
    min-height: 50px;
    background-color: ${props => props.theme.colors.red_main};
    width: 260px;
    color: ${props => props.theme.colors.white_main};
    font-size: 16px;
    border-radius: 4px;
    border: none;
  }
`;
