import React from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {toggleClassModal} from '../../../../redux/actions/modals';
import FilterIcon from '../../../../assets/images/FilterIcon';
import TagBased from './TagBased';
import {idCheck} from '../../../../utilities/common';
import Trainers from './Trainers';
import Bookmarked from './Bookmarked';
import TimeSelector from './TimeSelector';
import {
  setOndemandFilters,
  updateOndemandQuery
} from '../../../../redux/actions/filters';

const OndemandFilters = () => {
  const dispatch = useDispatch();
  const showFilters = useSelector(state => state.modals.classes.filters);
  const userInfo = useSelector(state => state.user);
  const filters = useSelector(state => state.filters.onDemand.info);

  const setFilters = values => {
    dispatch(setOndemandFilters(values));
    dispatch(updateOndemandQuery());
  };

  const handelFilters = (type, data) => {
    switch (type) {
      case 'bookmark':
        setFilters({
          ...filters,
          bookmarked: !filters.bookmarked?.length ? [userInfo.uuid] : []
        });
        break;
      case 'duration':
        setFilters({...filters, duration: data});
        break;
      case 'equipment':
        setFilters({
          ...filters,
          equipment: idCheck(filters.equipment, data, true)
        });
        break;
      case 'music':
        setFilters({
          ...filters,
          music: idCheck(filters.music, data, true)
        });
        break;
      case 'fitness level':
        setFilters({
          ...filters,
          difficulty: idCheck(filters.difficulty, data, true)
        });
        break;
      case 'trainer':
        setFilters({
          ...filters,
          trainers: idCheck(filters.trainers, data, true, true)
        });
        break;
      default:
        break;
    }
  };

  return showFilters ? (
    <MainWrapper>
      <TitleContainer>
        <h2>Filter Classes</h2>
        <FilterIcon
          className={'filter-btn'}
          onClick={() => dispatch(toggleClassModal('filters'))}
        />
      </TitleContainer>
      <TimeSelector handelFilters={handelFilters} values={filters.duration} />
      <TagBased category={'fitness level'} handelFilters={handelFilters} />
      <TagBased category={'equipment'} handelFilters={handelFilters} />
      <Trainers handelFilters={handelFilters} trainers={filters.trainers} />
      <TagBased category={'music'} handelFilters={handelFilters} />
      <Bookmarked
        handelFilters={handelFilters}
        bookmarked={filters.bookmarked}
      />
    </MainWrapper>
  ) : null;
};

export default OndemandFilters;

const MainWrapper = styled.div`
  padding: 20px;
  color: ${props => props.theme.colors.white_main};
  position: absolute;
  left: 250px;
  width: 300px;
  border: 2px solid ${props => props.theme.colors.grey_base};
  z-index: 100;
  background-color: ${props => props.theme.colors.grey_base};

  & h2 {
    font-weight: 400;
  }

  .selected {
    background-color: ${props => props.theme.colors.teal_main} !important;
    font-weight: 600;
  }

  @media (max-width: 770px) {
    left: 0;
    width: 90vw;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filter-btn {
    display: none;
    background-color: none;
    padding: 10px;
    height: 25px;
    width: 25px;
    margin-right: 25px;
    cursor: pointer;

    @media (max-width: 750px) {
      display: block;
    }
  }
`;
