import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {user} from '../../../utilities/apiCalls';
import Error from '../../common/Error';
import {emailRegex} from '../../../utilities/regex';
import MessageBox from '../../common/MessageBox';
import UniversalButton from '../../common/UniversalButton';

const EmailForm = props => {
  const {register, errors, handleSubmit} = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const onSubmit = async event => {
    setSubmitted(false);
    props.setProcessing(true);
    user.resetPassword(event.email).then(payload => {
      setEmailSent(payload);
      setSubmitted(payload);
      props.setProcessing(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={props.styles.label}>
        <label style={{paddingRight: 5}}>Email</label>
        <Error message={errors.email?.message ? errors.email.message : ''} />
      </div>
      <input
        style={errors.email ? props.styles.hasError : props.styles.input}
        name={'email'}
        type={'text'}
        ref={register({
          required: {value: true, message: 'Email is required.'},
          pattern: {value: emailRegex, message: 'Invalid Email'}
        })}
      />
      <UniversalButton
        processing={props.processing}
        type={'submit'}
        label={'Reset Password'}
      />
      <MessageBox
        show={submitted}
        message={emailSent ? 'Email has been sent.' : 'Error sending email.'}
        error={!emailSent}
      />
    </form>
  );
};

export default EmailForm;
