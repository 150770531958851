import React, {useEffect} from 'react';

import {FBPixel} from '../../utilities/facebookPixel';
import Footer from '../common/Footer';
import UniversalButton from '../common/UniversalButton';
import apple from '../../assets/images/apple.png';
import {getCookie} from '../../utilities/common';
import googleplay from '../../assets/images/googleplay.png';
import {setUser} from '../../redux/actions/user';
import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {user} from '../../utilities/apiCalls';

const Created = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const userID = getCookie('x-user');
    user.getUserInfo(userID).then(payload => dispatch(setUser(payload.data)));
  }, [dispatch]);

  useEffect(() => {
    FBPixel.PageView();
  }, []);

  return (
    <MainWrapper>
      <div className="title-main">Your account is now active</div>
      <div className="instruction">
        Welcome to the Inspire Fitness family. You now have access to a Premium
        subscription with Live & On Demand workouts. Download the Inspire
        Fitness mobile app to take full advantage of everything that your
        Account has to offer. You can also use the web app on any browser, to
        access Live & On Demand workouts. To manage your Account, go to the web
        app.
      </div>
      <img
        className="phones"
        src={
          'https://inspire-studios-assets.s3-us-west-1.amazonaws.com/workout-planner-assests/app_mobile.png'
        }
        alt="email icon"
      />
      <div className="links">
        <a
          href="https://play.google.com/store/apps/details?id=com.inspirefit"
          target="_blank"
          rel="noreferrer">
          <img className="links-icons" src={googleplay} alt="Google Play" />
        </a>
        <a
          href="https://itunes.apple.com/us/app/if-live/id1324164741"
          target="_blank"
          rel="noreferrer">
          <img className="links-icons" src={apple} alt="Apple App Store" />
        </a>
      </div>
      <UniversalButton
        label={'GO TO WEB APP'}
        onClick={() => {
          history.push('/video');
        }}
      />

      <Footer />
    </MainWrapper>
  );
};

export default Created;

const MainWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.white_main};

  .text-step {
    font-size: 13px;
    padding: 0px 0px 20px 0px;
  }

  .title-main {
    font-size: 28px;
    text-align: center;
    line-height: 1;
    margin-bottom: 21px;
    margin-top: 50px;
  }

  .instruction {
    text-align: center;
    font-size: 16px;
    opacity: 0.7;
    padding: 5px;
  }

  .Link {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
  }

  .links {
    display: flex;
    margin: 0;

    &-icons {
      width: 88px;
      height: 28px;
      margin: 20px 10px;
      padding: 5px;
      border: 1px solid ${props => props.theme.colors.white_main};
      border-radius: 5px;
      cursor: pointer;
    }
  }

  @media (max-width: 400px) {
    .btn-submit {
      width: 200px;
    }

    .title-main {
      margin-top: 10px;
    }
  }

  .icon {
    width: 36px;
    height: 36px;
    margin: 22px auto;
  }

  .phones {
    margin-top: 29px;

    @media (max-width: 700px) {
      width: 200vw;
    }
  }

  .email {
    position: absolute;
    bottom: 40px;
  }

  .resend {
    text-decoration: underline;
  }
`;
