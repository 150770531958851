import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import moment from 'moment';

const ElapsedClock = props => {
  const [time, setTime] = useState(moment());
  const intervalID = useRef(null);

  useEffect(() => {
    intervalID.current = setInterval(() => tick(), 1000);
    return () => clearInterval(intervalID.current);
  }, []);

  const tick = () => setTime(moment());
  const clockText = moment(time.diff(props.startTime)).format('mm:ss');

  return (
    <MainWrapper>
      <p>{`${clockText} Elapsed`}</p>
    </MainWrapper>
  );
};

export default ElapsedClock;

const MainWrapper = styled.div`
  & p {
    font-size: 0.7em;
    margin: 1px;
    align-text: left;
    color: ${props => props.theme.colors.orange_main};
  }
`;
