import React from 'react';
import styled from 'styled-components';
import Social from './Social';

const Footer = () => {
  return (
    <MainContainer>
      <Social></Social>
      <div className="links">
        <a
          href="https://inspirefitness.com/support"
          target="_blank"
          rel="noreferrer">
          Support
        </a>
        <a
          href="https://inspirefitness.com/privacy-policy/"
          target="_blank"
          rel="noreferrer">
          Policies
        </a>
        <a
          href="https://inspirefitness.com/shop"
          target="_blank"
          rel="noreferrer">
          Equipment
        </a>
        <a
          href="http://inspirefitnessstudios.com/"
          target="_blank"
          rel="noreferrer">
          Fitness Studio
        </a>
      </div>
      <div className="watermark">
        <p>©{new Date().getFullYear()}. Health in Motion, LLC.</p>
      </div>
    </MainContainer>
  );
};

export default Footer;

const MainContainer = styled.footer`
  max-width: 350px;
  margin: 0 auto;

  & div {
    margin-bottom: 30px;
  }

  .links {
    display: flex;
    justify-content: space-between;
    width: 350px;
    margin: 0 auto 30px auto;

    & a {
      color: ${props => props.theme.colors.white_main};
      text-decoration: none;
    }

    @media (max-width: 450px) {
      margin: 0 auto;
      font-size: 0.875em;
    }

    @media (max-width: 350px) {
      width: 90vw;
    }
  }

  .watermark {
    text-align: center;
    color: ${props => props.theme.colors.grey_6};
  }

  @media (max-width: 450px) {
    width: 100vw;
    margin: 0 auto;
  }
`;
