import React from 'react';
import {returnUrlForThumbnail} from '../../utilities/common';

const UniversalImage = props => {
  const thumbnail = returnUrlForThumbnail(props.image, props.type);

  return (
    <img
      style={{
        ...props.style,
        width: props.width,
        height: props.height
      }}
      src={thumbnail}
      alt="Thumbnail of Video"
    />
  );
};

export default UniversalImage;
