/* eslint-disable */
import React, {PureComponent} from 'react';
import {getColors} from '../../../../assets/styles/colors';

export default class Date extends PureComponent {
  // Style helper functions that merge active date styles with the default ones
  // when rendering a date that was selected by user or was set active by default

  getContainerStyle = () => ({
    ...styles.container,
    ...(this.props.isActive ? styles.containerActive : {})
  });

  getDayStyle = () => ({
    ...styles.text,
    ...styles.day,
    ...(this.props.isActive ? styles.textActive : {})
  });

  getDateStyle = () => ({
    ...styles.text,
    ...styles.date,
    ...(this.props.isActive ? styles.textActive : {})
  });

  // Call `onRender` and pass component's with when rendered
  // onLayout = (event: {
  //   nativeEvent: {
  //     layout: {x: number, y: number, width: number, height: number}
  //   }
  // }) => {
  //   const {index, onRender} = this.props;
  //   const {
  //     nativeEvent: {
  //       layout: {width}
  //     }
  //   } = event;
  //   onRender(index, width);
  // };

  // Call `onPress` passed from the parent component when date is pressed
  onPress = date => {
    const {index, onSelectDay} = this.props;
    onSelectDay(index);
  };

  render() {
    const {date} = this.props;
    return (
      <div
        style={this.getContainerStyle()}
        // onLayout={this.onLayout}
        onClick={() => this.onPress(date)}>
        <p style={this.getDayStyle()}>{date.format('ddd').toUpperCase()}</p>
        <p style={this.getDateStyle()}>{date.format('DD')}</p>
      </div>
    );
  }
}

const styles = {
  container: {
    borderBottomColor: 'transparent',
    borderBottomWidth: 2,
    padding: '10px 15px',
    backgroundColor: getColors().grey_base,
    cursor: 'pointer'
  },
  containerActive: {
    borderBottomColor: getColors().white_main
  },
  day: {
    fontSize: 12,
    margin: 0
  },
  date: {
    fontSize: 22,
    margin: 0
  },
  text: {
    color: getColors().grey_disabled,
    textAlign: 'center'
  },
  textActive: {
    color: getColors().white_main
  }
};
