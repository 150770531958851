import React, {Component} from 'react';

import UniversalButton from '../UniversalButton';
import styled from 'styled-components';

class CancelSubError extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return this.props.showModal ? (
      <Background ref="subModal">
        <Modal>
          <div className="text-title">Error Canceling Subscription</div>
          <div className="text-body">
            <div className="msg-error">
              <span>Please contact </span>
              <a
                href="https://inspirefitness.com/page/support"
                target="_blank"
                rel="noreferrer">
                support
              </a>
            </div>
          </div>
          <UniversalButton
            onClick={() =>
              this.props.setErrorState({
                showModal: false,
                cancelError: false
              })
            }
            label={'Back to Account'}
          />
        </Modal>
      </Background>
    ) : null;
  }
}

export default CancelSubError;

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: ${props => props.theme.colors.grey_base}CF;

  @media (max-width: 450px) {
    border-radius: 0;
  }
`;

const Modal = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.colors.grey_4};
  position: absolute;
  width: 500px;
  height: 250px;
  top: calc(50% - 100px);
  left: calc(50% - 250px);
  color: ${props => props.theme.colors.white_main};
  display: flex;
  flex-direction: column;

  @media (max-width: 750px) {
    width: 100vw;
    top: 5vh;
    left: 0;
    margin-top: 40px;
    border-radius: 0;
  }

  .text {
    &-title {
      margin: 20px 20px 12px 24px;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.4;
    }
    &-body {
      font-size: 16px;
      line-height: 1.5;
      margin: 0px 26px 0px 24px;
    }
  }

  .msg {
    &-error {
      color: ${props => props.theme.colors.red_error};
      padding: 2px;

      & a {
        color: ${props => props.theme.colors.red_error};
      }
    }
  }
`;
