import React from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import Details from './Details';
import InstructorInfo from './InstructorInfo';

const LiveCard = props => {
  const history = useHistory();
  const encoding = props.videoInfo.recording_status === 'encoding';
  const canJoin = props.videoInfo.recording_status === 'imminent' || encoding;

  return (
    <MainWrapper
      canJoin={canJoin}
      onClick={async () => {
        if (canJoin) {
          history.push(`/video/${window.btoa(props.videoInfo.uuid)}`);
        }
      }}>
      <InstructorInfo videoInfo={props.videoInfo} />
      <Details videoInfo={props.videoInfo} canJoin={canJoin} />
      {canJoin && (
        <div className={'joinClass'}>
          <p>JOIN CLASS</p>
        </div>
      )}
      <img
        src={
          props.videoInfo.instructors?.[0]?.image_url === ''
            ? 'https://inspire-studios-assets.s3-us-west-1.amazonaws.com/instructor-images/inspire_logo_black_globe.png'
            : props.videoInfo.instructors?.[0]?.image_url
        }
        className={'instructorImg'}
        alt={`${props.videoInfo.instructors?.[0]?.first_name}`}
      />
    </MainWrapper>
  );
};

export default LiveCard;

const MainWrapper = styled.div`
  width: 350px;
  height: 200px;
  margin-right: 3%;
  margin-bottom: 3%;
  position: relative;
  background-image: linear-gradient(
    rgba(20, 129, 135, 0.5),
    rgba(27, 28, 28, 0.7)
  );
  cursor: ${props => (props.canJoin ? 'pointer' : 'default')};
  border-radius: 4px;

  .joinClass {
    position: absolute;
    bottom: 5%;
    right: 2.5%;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    border-radius: 7.5px;
    z-index: 10;
    background-color: ${props => props.theme.colors.orange_main};

    & p {
      font-size: 0.75em;
      margin: 0;
    }
  }

  .instructorImg {
    position: absolute;
    border-radius: 4;
    width: 50%;
    height: '100%';
    z-index: 0;
    opacity: 0.6;
    right: 0;
    bottom: 0;
  }
`;
