import { datadogLogs } from '@datadog/browser-logs';

export const logError = (msg, context, ...args) => {
  datadogLogs.logger.error(msg, context);
  console.log(`%c ${msg} Error: `, 'color:red', context, ...args);
};

export const logInfo = (msg, context, ...args) => {
  datadogLogs.logger.info(msg, context);
  console.log(`%c ${msg}`, 'color:lime', context, ...args);
};
