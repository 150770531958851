import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import UniversalButton from '../../common/UniversalButton';
import {setUser} from '../../../redux/actions/user';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';
import {user} from '../../../utilities/apiCalls';

const SerialNumber = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userID = useSelector(state => state.user.id);
  const [serialInput, setSerialInput] = useState('');
  const [serialSubmitMsg, setSerialSubmitMsg] = useState('');

  const handleSerialInputChange = event => {
    setSerialInput(event.target.value);
    if (serialInput.length === 0 && serialSubmitMsg === 'invalid') {
      setSerialSubmitMsg('');
    }
  };

  const handleSerialSubmit = async event => {
    event.preventDefault();

    if (serialInput.length === 0) {
      history.push('/subscription/payment');
      return;
    }

    const body = {
      promoCode: serialInput.trim().toUpperCase(),
      userUUID: userID
    };

    user.submitSerialPromo(body).then(payload => {
      if (!payload.error) {
        setSerialSubmitMsg('success');
        user.getUserInfo(userID).then(userPayload => {
          dispatch(setUser(userPayload.data));
        });
        setTimeout(() => {
          setSerialInput('');
          setSerialSubmitMsg('');
          props.setShowSerialSuccessModal(true);
        }, 1000);
      } else {
        history.push('/subscription/payment');
      }
    });
  };

  return (
    <MainWrapper>
      <SerialInput>
        <form className="serial-form" onSubmit={handleSerialSubmit}>
          <label className="serial-label">
            <p className={'label'}>
              Serial Number (optional)
              {serialSubmitMsg === 'success' && (
                <span> - Applied Successfully!</span>
              )}
            </p>
            <input
              className={
                serialSubmitMsg === 'invalid' && serialInput.length
                  ? 'serial-input serial-input-error'
                  : 'serial-input'
              }
              value={serialInput}
              onChange={handleSerialInputChange}
              type="text"
            />
            {serialSubmitMsg === 'invalid' && serialInput.length ? (
              <span className="serial-invalid-message serial-invalid-message-background">
                Serial Number not valid for promo
              </span>
            ) : (
              <span className="serial-invalid-message"></span>
            )}
          </label>
          <UniversalButton type={'submit'} label={'CONTINUE'} />
        </form>
      </SerialInput>
    </MainWrapper>
  );
};

export default SerialNumber;

const MainWrapper = styled.div`
  color: ${props => props.theme.colors.white_main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 450px) {
    height: 100%;
    max-width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
  }

  .title {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 30px;
    line-height: 1.3;
    align-self: flex-start;
  }
`;

const SerialInput = styled.div`
  margin-top: 40px;
  max-width: 450px;

  .serial {
    &-form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-label {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: ${props => props.theme.colors.white_main};
      font-weight: 600;
      letter-spacing: 0.86ps;

      & p {
        margin: 0;
        text-align: left;
      }
    }

    &-input {
      font-size: 16px;
      width: 390px;
      margin: 3px 0px 0px 0px;
      height: 50px;
      border-radius: 5px;
      border: solid 1px ${props => props.theme.colors.white_main};
      color: ${props => props.theme.colors.white_main};
      padding-left: 10px;
      box-sizing: border-box;
      display: inline-block;
      background: ${props => props.theme.colors.grey_4} !important;

      @media (max-width: 450px) {
        width: 300px;
      }

      &-error {
        border: solid 1px ${props => props.theme.colors.red_error};
      }
    }

    &-invalid-message {
      color: ${props => props.theme.colors.white_main};
      height: 15px;
      padding: 0px 5px 3px 5px;
      margin-top: 2px;
      border-radius: 4px;
      font-size: 12px;
      align-self: flex-start;

      &-background {
        background-color: ${props => props.theme.colors.red_error};
      }
    }
  }
`;
