/* eslint-disable */
import React, {PureComponent} from 'react';
import Date from './date';
import type Moment from 'moment';
import {sideScroll} from '../../../../utilities/sideScroll';
import LeftArrow from '../../../../assets/images/LeftArrow';
import RightArrow from '../../../../assets/images/RightArrow';
import {textButton} from '../../../../assets/styles/common';
import {getColors} from '../../../../assets/styles/colors';
import { logInfo } from '../../../../utilities/logger';

export default class Dates extends PureComponent {
  props: {
    // Currently active date index
    currentDateIndex: ?number,
    // Array of dates to render
    dates: Array<Moment>,
    // Callback to handle date select
    onSelectDay: (index: number) => void,
    // Callback to handle date render
    onRenderDay: (index: number, width: number) => void
  };

  render() {
    const {currentDateIndex, dates, onSelectDay, onRenderDay} = this.props;
    const scrollLeft = target => {
      sideScroll(target, 'left', 20, 210, 10);
    };
    const scrollRight = target => {
      logInfo('target', target);
      sideScroll(target, 'right', 20, 210, 10);
    };

    return (
      <div style={{display: 'grid', gridTemplateColumns: '25px 1fr 25px'}}>
        <button
          className="scroll-btn"
          style={textButton}
          onClick={() => {
            scrollLeft(this._scrollRef);
          }}>
          <LeftArrow style={{width: 20}} color={getColors().grey_6} />
        </button>
        <div
          ref={scrollView => {
            this._scrollRef = scrollView;
          }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            overflowX: 'scroll'
          }}>
          {dates.map((date, index) => (
            <div key={index}>
              <Date
                date={date}
                index={index}
                isActive={index === currentDateIndex}
                onSelectDay={onSelectDay}
                onRender={onRenderDay}
                key={index}
              />
            </div>
          ))}
        </div>
        <button
          className="scroll-btn"
          style={textButton}
          onClick={() => {
            scrollRight(this._scrollRef);
          }}>
          <RightArrow style={{width: 20}} color={getColors().grey_6} />
        </button>
      </div>
    );
  }
}
