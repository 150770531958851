import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';
import {getColors} from '../../../../assets/styles/colors';

const TimeSelector = props => {
  const colors = getColors();
  const [values, setValues] = useState(props.values);

  useEffect(() => {
    setValues(props.values);
  }, [props.values]);

  const selectorLabel = {
    0: '0',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
    110: '110',
    120: '120'
  };
  return (
    <MainWrapper>
      <p>DURATION</p>
      <Range
        className="range"
        defaultValue={[0, 120]}
        value={values}
        min={0}
        max={120}
        step={10}
        dots={false}
        allowCross={false}
        marks={selectorLabel}
        onChange={value => setValues(value)}
        onAfterChange={() => {
          props.handelFilters('duration', values);
        }}
        trackStyle={[
          {backgroundColor: colors.teal_main},
          {backgroundColor: colors.teal_main}
        ]}
        handleStyle={[
          {backgroundColor: colors.teal_main},
          {backgroundColor: colors.teal_main}
        ]}
        railStyle={{backgroundColor: colors.grey_3}}
        activeDotStyle={{backgroundColor: colors.teal_main}}
      />
    </MainWrapper>
  );
};

export default TimeSelector;

const MainWrapper = styled.section`
  display: flex;
  flex-direction: column;
  height: 10vh;

  .range {
    width: 90%;
    margin: 0 auto 0 0;
  }

  .rc-slider-mark-text {
    color: ${props => props.theme.colors.white_main};
  }

  .rc-slider-dot-active {
    border-color: ${props => props.theme.colors.teal_main} !important;
    background-color: ${props => props.theme.colors.teal_main} !important;
  }

  .rc-slider-dot {
    border-color: ${props => props.theme.colors.teal_main};
    background-color: ${props => props.theme.colors.teal_main};
  }

  .rc-slider-handle {
    border-color: ${props => props.theme.colors.teal_main};

    &:hover {
      border-color: ${props => props.theme.colors.teal_main};
    }
    &:focus {
      border-color: ${props => props.theme.colors.teal_main};
    }
  }
`;
