import {encode as btoa} from 'base-64';
import moment from 'moment';

/**
 * @param {Array} Initial_Array
 * @param {Number or Obj} New_Item
 * @param {Boolean} Array_of_Objs
 */
export const idCheck = (arr, newItem, objBased, uuid) => {
  const newArr = [...arr];
  if (objBased) {
    if (uuid) {
      if (newArr.some(e => e.uuid === newItem.uuid)) {
        return newArr.filter(item => item.uuid !== newItem.uuid);
      } else {
        newArr.push(newItem);
        return newArr;
      }
    } else if (newItem.id) {
      if (newArr.some(e => e.id === newItem.id)) {
        return newArr.filter(item => item.id !== newItem.id);
      } else {
        newArr.push(newItem);
        return newArr;
      }
    } else if (newItem.name) {
      if (newArr.some(e => e.name === newItem.name)) {
        return newArr.filter(item => item.name !== newItem.name);
      } else {
        newArr.push(newItem);
        return newArr;
      }
    }
  } else {
    if (newArr.includes(newItem)) {
      return newArr.filter(item => item !== newItem);
    } else {
      newArr.push(newItem);
      return newArr;
    }
  }
};

/**
 * @param {String} IMAGE_URL
 * @param {String} SIZE_TYPE
 */
export const returnUrlForThumbnail = (url, type) => {
  const getPath = /\.com\/(.*)/;
  const path = getPath.exec(url);
  const request = {
    bucket: 'inspire-studios-assets', // S3 bucket name
    key: path[1].replace('inspire-studios-assets/', ''), // path to your file on the S3 bucket (ex. photos/face1.png)
    edits: {
      webp: true,
      jpeg: true,
      resize: {
        width: type.width,
        height: type.height,
        fit: 'cover'
      }
    }
  };
  const strRequest = JSON.stringify(request);
  const encRequest = btoa(strRequest);
  const newUrl = `https://d2bbo9kae5wyum.cloudfront.net/${encRequest}`; // the image url

  return newUrl;
};

export const imageSizes = {
  exercise: {width: 144, height: 144},
  cards: {width: 640, height: 360}
};

export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const sortLiveClasses = arr => {
  const sortedClasses = {};

  arr.forEach(element => {
    const date = moment(element.start_time).format('YYYY-MM-DD');
    if (element.live) {
      if (!sortedClasses[date]) {
        sortedClasses[date] = [element];
      } else {
        sortedClasses[date].push(element);
      }
    }
  });

  if (!!Object.keys(sortedClasses).length) {
    Object.keys(sortedClasses).map(day =>
      sortedClasses[day].sort(
        (a, b) =>
          moment(a.start_time).format('HHmm') -
          moment(b.start_time).format('HHmm')
      )
    );
  }

  return sortedClasses;
};

export const getCookie = name => {
  const neededCookie = document.cookie.match(
    '(^|;) ?' + name + '=([^;]*)(;|$)'
  );
  return neededCookie ? neededCookie[2] : null;
};

// TODO: this overwrites; should parse document.cookie and merge
// I did try react-cookies library but run into some issues
export const setCookie = (name, value) => {
  document.cookie = `${name}=${value};`;
};

export const createActiveFilters = filtersObj => {
  const activeFiltersArray = [];
  const nameValues = ['equipment', 'music', 'difficulty'];
  for (let key in filtersObj) {
    filtersObj[key].map(item => {
      if (nameValues.includes(key)) {
        activeFiltersArray.push({name: item.name, property: key});
      } else if (key === 'trainers') {
        const trainerName = `${item.first_name} ${item.last_name}`;
        activeFiltersArray.push({
          name: trainerName,
          property: 'trainers',
          uuid: item.uuid
        });
      }
      return null;
    });
  }
  if (filtersObj.duration[0] !== 0) {
    activeFiltersArray.push({
      name: `min length ${filtersObj.duration[0]}`,
      property: 'duration'
    });
  }
  if (filtersObj.duration[1] !== 120) {
    activeFiltersArray.push({
      name: `max length ${filtersObj.duration[1]}`,
      property: 'duration'
    });
  }
  if (filtersObj.bookmarked?.length) {
    activeFiltersArray.push({
      name: 'My Favorites Only',
      property: 'bookmarked'
    });
  }

  return activeFiltersArray;
};

export const allEquipmentObjects = [
  {name: 'FT1/FTX'},
  {name: 'FT2'},
  {name: 'SF3'},
  {name: 'SCS'},
  {name: 'BL1'},
  {name: 'M1'},
  {name: 'M2'},
  {name: 'M3'},
  {name: 'M5'},
  {name: 'Free Weights'},
  {name: 'Bodyweight'},
  {name: 'Indoor Cycle'},
  {name: 'Rower'},
  {name: 'Strider'},
  {name: 'Functional Trainer'},
  {name: 'Multi Gym'}
];
