import React, {useEffect} from 'react';
import {datadogLogs} from '@datadog/browser-logs';
import {datadogRum} from '@datadog/browser-rum'

const DataDogContainer = () => {
  useEffect(() => {
    const env = window.location.href.includes('localhost') ? 'local' :
      window.location.href.includes('beta') ? 'beta' : 'prod';

    datadogLogs.init({
      clientToken: 'pub1a4935f8a07142e0e33bedeb198c3247',
      site: 'datadoghq.com',
      service: 'webapp',
      version: '0.1.1',
      env: env,
      isCollectingError: true,
      sampleRate: 100
    });

    datadogRum.init({
      applicationId: '59713487-8fe9-42f2-9bc7-a6658a076bf0',
      clientToken: 'pubfbd8798ac4a1154420ad6f61a27b54e1',
      site: 'datadoghq.com',
      service: 'webapp',
      version: '0.1.1',
      env: env,
      isCollectingError: true,
      sampleRate: 100,
      trackInteractions: true,
    });
  }, []);

  return <div />;
};

export default DataDogContainer;
