import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import UniversalButton from '../../../common/UniversalButton';
import {promoStates} from '../../../../redux/reducers/registration';
import {setPromoState} from '../../../../redux/actions/registration';
import {setUser} from '../../../../redux/actions/user';
import styled from 'styled-components';
import {user} from '../../../../utilities/apiCalls';

const PromoCode = () => {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.user.id);
  const [promoInput, setPromoInput] = useState('');
  const [promoSubmitMsg, setPromoSubmitMsg] = useState('');
  const [processing, setProcessing] = useState(false);

  const handlePromoInputChange = event => {
    setPromoInput(event.target.value);
    if (promoInput.length === 0 && promoSubmitMsg === 'invalid') {
      setPromoSubmitMsg('');
    }
  };

  const handlePromoSubmit = async event => {
    event.preventDefault();
    setProcessing(true);
    const body = {
      promoCode: promoInput,
      userUUID: userID
    };

    user.submitSerialPromo(body).then(payload => {
      if (payload.data) {
        setPromoSubmitMsg('success');
        user.getUserInfo(userID).then(userPayload => {
          dispatch(setUser(userPayload.data));
        });
        setTimeout(() => {
          setPromoInput('');
          setPromoSubmitMsg('');
          setProcessing(false);
          dispatch(setPromoState(promoStates.success));
        }, 1000);
      } else {
        setPromoSubmitMsg('invalid');
        setProcessing(false);
      }
    });
  };

  return (
    <MainWrapper>
      <PromoInput>
        <form className="promo-form" onSubmit={handlePromoSubmit}>
          <label className="promo-label">
            <div>
              Promo Code
              {promoSubmitMsg === 'success' && (
                <span> - Applied Successfully!</span>
              )}
            </div>
            <input
              className={
                promoSubmitMsg === 'invalid' && promoInput.length
                  ? 'promo-input promo-input-error'
                  : 'promo-input'
              }
              value={promoInput}
              onChange={handlePromoInputChange}
              type="text"
            />
            {promoSubmitMsg === 'invalid' && promoInput.length ? (
              <span className="promo-invalid-message promo-invalid-message-background">
                Promo code does not exist
              </span>
            ) : (
              <span className="promo-invalid-message"></span>
            )}
          </label>
          <UniversalButton
            type={'submit'}
            style={{marginTop: 10}}
            processing={processing}
            className={promoInput.length < 10 ? 'disabled' : ''}
            disabled={promoInput.length < 10}
            label={'Apply Promo Code'}
          />
        </form>
      </PromoInput>
    </MainWrapper>
  );
};

export default PromoCode;

const MainWrapper = styled.div`
  border-radius: 2px;
  color: ${props => props.theme.colors.white_main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 450px) {
    width: 75vw;
    padding: 25px;
    margin: 5px 0;
  }

  @media (max-width: 450px) {
    height: 100%;
    max-width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
  }

  .title {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 30px;
    line-height: 1.3;
    align-self: flex-start;
  }
`;

const PromoInput = styled.div`
  margin-top: 40px;
  max-width: 450px;

  .disabled {
    background-color: ${props => props.theme.colors.grey_4} !important;
    cursor: not-allowed !important;
  }

  .promo {
    &-form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-label {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: ${props => props.theme.colors.white_main};
      font-weight: 600;
      letter-spacing: 0.86ps;
    }

    &-input {
      font-size: 16px;
      width: 390px;
      margin: 3px 0px 0px 0px;
      height: 50px;
      border-radius: 5px;
      border: solid 1px ${props => props.theme.colors.white_main};
      color: ${props => props.theme.colors.white_main};
      padding-left: 10px;
      box-sizing: border-box;
      display: inline-block;
      background: ${props => props.theme.colors.grey_4} !important;

      @media (max-width: 450px) {
        width: 300px;
      }

      &-error {
        border: solid 1px ${props => props.theme.colors.red_error};
      }
    }

    &-invalid-message {
      color: ${props => props.theme.colors.white_main};
      height: 15px;
      padding: 0px 5px 3px 5px;
      margin-top: 2px;
      border-radius: 4px;
      font-size: 12px;
      align-self: flex-start;

      &-background {
        background-color: ${props => props.theme.colors.red_error};
      }
    }
  }
`;
