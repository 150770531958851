import {SET_USER, UPDATE_SUBSCRIPTION_INFO} from '../actions/typeConstants';

const defaultState = {};

const user = (state = defaultState, action) => {
  let newState = {...state};

  switch (action.type) {
    case SET_USER:
      if (!action.data) {
        return state;
      }
      newState = {...newState, ...action.data};
      return newState;

    case UPDATE_SUBSCRIPTION_INFO:
      if (!action.data) {
        return state;
      }
      newState.subscription = action.data;

      return newState;

    default:
      return state;
  }
};

export default user;
