/* eslint-disable */

import React from 'react';
import styled from 'styled-components';
import {getColors} from '../../../assets/styles/colors';

const LiveClassEnded = props => {
  const instructorNames =
    props.videoInfo && props.videoInfo.instructors
      ? props.videoInfo.instructors
          .map(item => `${item.first_name} ${item.last_name}`)
          .join(', ')
      : ['N/A'];

  return (
    <MainWrapper>
      <p className="text">{`Thanks for taking ${instructorNames}'s class`}</p>
      <p className="text">Stay Inspired!</p>
    </MainWrapper>
  );
};

export default LiveClassEnded;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(https://s3-us-west-1.amazonaws.com/inspire-studios-assets/instructor-images/inspire_logo_black_globe.png);
  background-color: ${getColors().grey_4};
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 500px;

  .text {
    font-size: 22px;
    color: ${getColors().white_main};
  }
`;
