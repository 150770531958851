import {combineReducers} from 'redux';
import config from './config';
import filters from './filters';
import user from './user';
import registration from './registration';
import websocket from './websocket';
import modals from './modals';

const reducers = () =>
  combineReducers({
    config,
    filters,
    modals,
    user,
    registration,
    websocket
  });

export default reducers;
