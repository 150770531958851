import React, {useState} from 'react';
import styled from 'styled-components';
import EmailForm from './EmailForm';
import createStyles from '../../Login/styles';
import TopLogo from '../../common/TopLogo';
import Footer from '../../common/Footer';

const ChangePassword = () => {
  const [processing, setProcessing] = useState(false);
  const styles = createStyles({processing});

  return (
    <div style={{width: '100vw'}}>
      <TopLogo style={{margin: '20px 0 0 20px '}} />
      <MainWrapper>
        <div style={styles.title}>Reset Password</div>
        <EmailForm
          processing={processing}
          setProcessing={setProcessing}
          styles={styles}
        />
      </MainWrapper>
      <Footer />
    </div>
  );
};

export default ChangePassword;

const MainWrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
  padding: 25px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.white_main};

  @media (max-width: 450px) {
    width: 90vw;
    margin: 0 auto;
  }
`;
