import React from 'react';
import {getColors} from '../../assets/styles/colors';

const Error = props => {
  return props.message !== '' ? (
    <div style={{color: getColors().red_error, height: 14}}>
      {props.message}
    </div>
  ) : null;
};

export default Error;
