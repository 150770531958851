import React, {useState} from 'react';
import styled from 'styled-components';
import createFormStyles from '../../../assets/styles/createFormStyles';
import BackButton from '../../common/BackButton';
import SideMenu from '../../Nav/SideMenu';
import ProfileForm from './ProfileForm';

const SignIn = () => {
  const [processing, setProcessing] = useState(false);
  const styles = createFormStyles({processing});

  return (
    <div>
      <SideMenu />
      <BackButton routeTo={'/account'} label={'Back to Account'} />
      <MainWrapper>
        <div style={{...styles.title, marginBottom: 10, marginTop: 20}}>
          Edit Profile
        </div>
        <ProfileForm
          processing={processing}
          setProcessing={setProcessing}
          styles={styles}
        />
      </MainWrapper>
    </div>
  );
};

export default SignIn;

const MainWrapper = styled.div`
  max-width: 350px;
  margin: 0 250px;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.white_main};

  @media (max-width: 770px) {
    width: 90vw;
    margin: 0;
    padding: 5vw;
  }
`;
