import {SET_REFRESH, SET_TRAINERS, SET_METADATA} from './typeConstants';

export const setTrainers = data => ({
  type: SET_TRAINERS,
  data
});
export const setMetadata = data => ({
  type: SET_METADATA,
  data
});

export const setRefresh = data => ({
  type: SET_REFRESH,
  data
});
