import {Link} from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';

const ChangePasswordModal = ({showModal, successfulReset, closeModal}) => {
  return showModal ? (
    <Background>
      <Modal>
        {!successfulReset && (
          <div className="close-modal" onClick={closeModal}>
            X
          </div>
        )}

        <h1 className="title">Change Password</h1>

        {successfulReset ? (
          <p className="body green">SUCCESS!</p>
        ) : (
          <p className="body red">ERROR</p>
        )}

        {successfulReset ? (
          <div className="authnet-user-msg">
            <div className="authnet-user-msg-container">
              <div className="authnet-user-msg-body">
                Password reset successfully.
              </div>
              <div className="authnet-user-msg-body">
                Go to{' '}
                <Link className="link" to="/video">
                  workouts.
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="authnet-user-msg">
            <div className="authnet-user-msg-container">
              <div className="authnet-user-msg-body">
                Unable to reset password.
              </div>
              <span className="authnet-user-msg-body">Please contact </span>
              <a
                className="authnet-user-msg-body link"
                href="https://inspirefitness.com/support"
                target="_blank"
                rel="noreferrer">
                support.
              </a>
            </div>
          </div>
        )}
      </Modal>
    </Background>
  ) : null;
};

export default ChangePasswordModal;

const Background = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 0;
  background-color: ${props => props.theme.colors.grey_base}CF;
`;

const Modal = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.colors.grey_4};
  position: absolute;
  width: 450px;
  height: 200px;
  top: calc(50% - 100px);
  left: calc(50% - 225px);
  z-index: 2;
  color: ${props => props.theme.colors.white_main};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 450px) {
    height: 100%;
    max-width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
  }

  .close-modal {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
    color: ${props => props.theme.colors.white_main};
  }

  .title {
    align-self: start;
    font-size: 1.25rem;
    margin-top: 24px;
    margin-left: 24px;
    margin: 24px 0px 0px 24px;
    letter-spacing: 2px;
    line-height: 1.3;
  }
  .body {
    align-self: start;
    font-size: 1.125rem;
    margin-left: 24px;
    letter-spacing: 3px;
  }

  .green {
    color: ${props => props.theme.colors.green_success};
  }

  .red {
    color: ${props => props.theme.colors.red_error};
  }

  .btn {
    &-container {
      display: flex;
      align-self: flex-end;
    }

    &-sign {
      width: 75px;
      padding: 8px 16px;
      cursor: pointer;
      min-height: 50px;
      background-color: ${props => props.theme.colors.grey_4};
      color: white;
      font-size: 16px;
      border-radius: 4px;
      border: none;
      margin-right: 15px;
      position: absolute;
      right: 0;
    }
  }

  .authnet-user-msg {
    align-self: flex-start;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-container {
      margin: 0px 0px;
      width: 100%;
    }

    &-body {
      align-self: start;
      font-size: 1.2rem;
      letter-spacing: 3px;
    }
  }

  .link {
    color: ${props => props.theme.colors.orange_main};
  }
`;
