import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PasswordForm from './PasswordForm';
import createStyles from '../../Login/styles';
import TopLogo from '../../common/TopLogo';
import Footer from '../../common/Footer';
import { setCookie } from "../../../utilities/common";

const ChangePassword = (props) => {
  const [processing, setProcessing] = useState(false);
  const styles = createStyles({processing});

  // david: server set the cookies, i don't think we need to set again here
  useEffect(() => {
    const queryString = new URLSearchParams(props.location.search);
    if (queryString.has("x-pwreset")) {
      setCookie("x-pwreset", queryString.get("x-pwreset"));
    }
    if (queryString.has("x-user")) {
      setCookie("x-user", queryString.get("x-user"));
    }
  }, [props]);

  return (
    <MainWrapper>
      <TopLogo className={'logo'} />
      <div className={'container'}>
        <div style={styles.title}>Change Password</div>
        <PasswordForm
          processing={processing}
          setProcessing={setProcessing}
          styles={styles}
        />
        <Footer />
      </div>
    </MainWrapper>
  );
};

export default ChangePassword;

const MainWrapper = styled.div`
  .container {
    max-width: 350px;
    margin: 0 auto;
    padding: 25px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.colors.grey_base};
    color: ${props => props.theme.colors.white_main};
  }

  .logo {
    margin: 20px 0 0 20px;
  }
`;
