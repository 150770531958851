import React from 'react';
import UniversalImage from '../../common/UniversalImage';
import {imageSizes} from '../../../utilities/common';

const Thumbnail = props => {
  return (
    <UniversalImage
      image={props.videoInfo.thumbnail_url}
      type={imageSizes.cards}
      style={{
        maxWidth: 350,
        borderRadius: 4
      }}
    />
  );
};

export default Thumbnail;
