/* eslint-disable */
import React, {PureComponent} from 'react';
import moment from 'moment';
import Dates from './dates';
import styled from 'styled-components';

const {width: screenWidth} = window.screen.width;

const formatMonth = date => date.format('MMMM');

const formatYear = date => date.format('YYYY');
class Calendar extends PureComponent {
  static defaultProps = {
    // Show 5 days before the current day
    showDaysBeforeCurrent: 5,
    // And after
    showDaysAfterCurrent: 5
  };

  _scrollView;

  // Initialize the state with default values
  constructor(props) {
    super(props);
    this.state = {
      allDatesHaveRendered: false,
      dates: this.getDates(),
      dayWidths: undefined,
      scrollPositionX: 0,
      visibleMonths: undefined,
      visibleYears: undefined
    };
  }

  componentDidMount() {
    // Make initial onSelectDate call with initial selected date
    const {currentDateIndex} = this.props;
    this.handleOnSelectDate(currentDateIndex);
  }

  // Get an array of dates for showing in a horizontal scroll view
  getDates = () => {
    const {currentDate, showDaysBeforeCurrent, showDaysAfterCurrent} =
      this.props;

    // Go `showDaysBeforeCurrent` ago before today or custom `currentDate`
    const startDay = moment(currentDate || undefined).subtract(
      showDaysBeforeCurrent + 1,
      'days'
    );
    // Number of days in total
    const totalDaysCount = showDaysBeforeCurrent + showDaysAfterCurrent + 1;

    // And return an array of `totalDaysCount` dates
    return [...Array(totalDaysCount)].map(_ => startDay.add(1, 'day').clone());
  };
  // Returns a subset of dates currently visible on the screen
  getVisibleDates = () => {
    const {dates, dayWidths, scrollPositionX} = this.state;

    if (!dayWidths) {
      return;
    }

    let datePositionX = 0;
    let firstVisibleDateIndex = undefined;
    let lastVisibleDateIndex = undefined;

    // Iterate through `dayWidths` to  $FlowFixMe
    Object.values(dayWidths).some((width, index) => {
      if (
        firstVisibleDateIndex === undefined && // not set yet
        datePositionX >= scrollPositionX // first date visible
      ) {
        firstVisibleDateIndex = index > 0 ? index - 1 : index;
      }

      if (
        lastVisibleDateIndex === undefined && // not set yet
        datePositionX >= scrollPositionX + screenWidth // first date not visible behind the right edge
      ) {
        lastVisibleDateIndex = index;
      }

      // Increment date position by its width for the next iteration
      datePositionX += width;

      // return true when both first and last visible days found to break out of loop
      return !!(firstVisibleDateIndex && lastVisibleDateIndex);
    });

    // Return a subset of visible dates only
    return dates.slice(firstVisibleDateIndex, lastVisibleDateIndex);
  };
  // Format as a string the month(s) and the year(s) of the dates currently visible
  getVisibleMonthAndYear = () => {
    const {dates, visibleMonths, visibleYears} = this.state;

    // No `visibleMonths` or `visibleYears` yet
    if (!visibleMonths || !visibleYears) {
      // Return the month and the year of the very first date
      if (dates) {
        const firstDate = dates[0];
        return `${formatMonth(firstDate)}, ${formatYear(firstDate)}`;
      }
      return undefined;
    }

    // One or two months withing the same year
    if (visibleYears.length === 1) {
      return `${visibleMonths.join(' – ')},  ${visibleYears[0]}`;
    }

    // Two months within different years
    return visibleMonths
      .map((month, index) => `${month}, ${visibleYears[index]}`)
      .join(' – ');
  };
  // Update visible month(s) and year(s) of the dates currently visible on the screen
  updateVisibleMonthAndYear = () => {
    const {allDatesHaveRendered} = this.state;

    if (!allDatesHaveRendered) {
      return;
    }

    const visibleDates = this.getVisibleDates();

    if (!visibleDates) {
      return;
    }

    let visibleMonths = [];
    let visibleYears = [];

    visibleDates.forEach(date => {
      const month = formatMonth(date);
      const year = formatYear(date);
      if (!visibleMonths.includes(month)) {
        visibleMonths.push(month);
      }
      if (!visibleYears.includes(year)) {
        visibleYears.push(year);
      }
    });

    this.setState({
      visibleMonths,
      visibleYears
    });
  };

  onSelectDay = index => {
    // this.setState({currentDateIndex: index});
    this.props.setDateFunc(index, this.state.dates);
    this.handleOnSelectDate(index);
  };

  handleOnSelectDate = index => {
    const {onSelectDate} = this.props;
    const {dates} = this.state;
    const selectedDay = moment(dates[index]).format();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    onSelectDate?.(selectedDay, timezone);
  };

  onRenderDay = (index, width) => {
    const {dayWidths} = this.state;
    const {showDaysBeforeCurrent, showDaysAfterCurrent} = this.props;

    // Check whether all date have been rendered already
    const allDatesHaveRendered =
      dayWidths &&
      Object.keys(dayWidths).length >=
        showDaysBeforeCurrent + showDaysAfterCurrent;

    this.setState(
      prevState => ({
        allDatesHaveRendered,
        dayWidths: {
          // keep all existing widths added previously
          ...prevState.dayWidths,
          // keep the index for calculating scrolling position for each day
          [index]: width
        }
      }),
      () => {
        if (allDatesHaveRendered) {
          //this.scrollToCurrentDay();
          this.updateVisibleMonthAndYear();
        }
      }
    );
  };

  onScroll = event => {
    const {
      nativeEvent: {
        contentOffset: {x}
      }
    } = event;
    this.setState({scrollPositionX: x}, this.updateVisibleMonthAndYear);
  };
  render() {
    const {dates} = this.state;
    const {currentDateIndex} = this.props;
    return (
      <div style={{marginTop: 0, marginBottom: 10}}>
        <VideoScroll
          ref={scrollView => {
            this._scrollView = scrollView;
          }}
          horizontal={true} // Enable horizontal scrolling
          showsHorizontalScrollIndicator={false} // Hide horizontal scroll indicators
          automaticallyAdjustContentInsets={false} // Do not adjust content automatically
          scrollEventThrottle={100}
          onScroll={this.onScroll}>
          <Dates
            dates={dates}
            currentDateIndex={currentDateIndex}
            onSelectDay={this.onSelectDay}
            onRenderDay={this.onRenderDay}
          />
        </VideoScroll>
      </div>
    );
  }
}

export default Calendar;

const VideoScroll = styled.div`
  .scroll-btn {
    max-width: 25px;
    margin: auto 0;
    transition: all 0.5s ease-in-out;
    border-color: none !important;
    box-shadow: none;
    cursor: pointer;

    &:hover {
      transform: scale(1.25);
    }

    & svg {
      margin: 0 5px;
      padding: 0;
    }
  }
`;
