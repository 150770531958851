import {getColors} from '../../../assets/styles/colors';

const createStyles = props => {
  return {
    link: {
      opacity: 0.4,
      textDecoration: 'none',
      fontSize: 14,
      lineHeight: 1.3,
      cursor: 'pointer',
      color: getColors().white_main
    },
    subLink: {
      fontSize: 12,
      lineHeight: 2,
      display: 'block',
      marginLeft: 10,
      marginBottom: 10
    }
  };
};

export default createStyles;
