import React from 'react';
import styled from 'styled-components';
import email from '../../../assets/images/email.png';

const VerificationBody = () => {
  return (
    <MainWrapper>
      <div className="title-main">{'Thanks for signing up!'}</div>
      <div className="title-sub">{'Check your email to continue'}</div>
      <div className="instruction">
        {'We sent you a verification email, so that we know it’s really you.'}
      </div>
      <div className="instruction">
        {'Check your email and click the in-email link to verify.'}
      </div>

      <img className="icon" src={email} alt="email icon" />
    </MainWrapper>
  );
};

export default VerificationBody;

const MainWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.white_main};

  .text-step {
    font-size: 13px;
    padding: 0px 0px 20px 0px;
  }

  .title-main {
    font-size: 30px;
    text-align: center;
    line-height: 1;
  }

  .title-sub {
    font-size: 30px;
    text-align: center;
    padding: 20px 10px;
    line-height: 1;
  }

  .instruction {
    text-align: center;
    font-size: 18px;
    opacity: 0.7;
    padding: 5px;
  }

  .Link {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
  }

  .btn-submit {
    margin: 0px auto 30px auto;
    padding: 12px;
    cursor: pointer;
    min-height: 50px;
    background-color: rgb(218, 41, 28);
    width: 66%;
    color: ${props => props.theme.colors.white_main};
    font-size: 16px;
    border-radius: 5px;
    border: none;
  }

  .icon {
    width: 100px;
    height: 100px;
    margin: 75px auto;
  }

  .email {
    position: absolute;
    bottom: 40px;
  }

  .resend {
    text-decoration: underline;
  }
`;
