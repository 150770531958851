import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import FilterIcon from '../../../assets/images/FilterIcon';
import {
  clearFilters,
  setOndemandFilters,
  updateOndemandQuery
} from '../../../redux/actions/filters';
import {toggleClassModal} from '../../../redux/actions/modals';
import {allEquipmentObjects} from '../../../utilities/common';
import VideoList from './VideoList';

const Ondemand = () => {
  const dispatch = useDispatch();
  const equipment = allEquipmentObjects;
  const userInfo = useSelector(state => state.user);
  const activeFilters = useSelector(
    state => state.filters.onDemand.activeFilters
  );
  const filters = useSelector(state => state.filters.onDemand.info);

  const removeActiveFilter = filter => {
    const copyFilters = {...filters};
    if (filter.property === 'bookmarked') {
      copyFilters[filter.property] = [];
    } else if (filter.property === 'duration') {
      if (filter.name.includes('max')) {
        copyFilters[filter.property] = [copyFilters.duration[0], 120];
      } else {
        copyFilters[filter.property] = [0, copyFilters.duration[1]];
      }
    } else if (filter.property === 'trainers') {
      let newTrainers = copyFilters[filter.property].filter(
        item => item.uuid !== filter.uuid
      );
      copyFilters.trainers = newTrainers;
    } else {
      let newFilter = copyFilters[filter.property].filter(
        item => item.name !== filter.name
      );
      copyFilters[filter.property] = newFilter;
    }
    dispatch(setOndemandFilters(copyFilters));
    dispatch(updateOndemandQuery());
    return copyFilters;
  };

  const mappedActiveFilters = activeFilters.map(item => {
    return (
      <div
        className="active-filter-btn"
        key={item.name}
        onClick={() => removeActiveFilter(item)}>
        <div className="active-filter-x">X</div>
        <div className="active-filter-name">{item.name}</div>
      </div>
    );
  });

  return (
    <Section>
      <div className="on-demand-row">
        <div className="on-demand-header-container">
          <button
            className="filters-btn"
            onClick={() => dispatch(toggleClassModal('filters'))}>
            <FilterIcon />
          </button>
          <h2 className="font-weight-light">On Demand Workouts</h2>
          {activeFilters?.length ? (
            <div
              className="clear-filters-btn"
              onClick={() => dispatch(clearFilters())}>
              clear filters
            </div>
          ) : null}
        </div>
        <div className="active-filter-container">{mappedActiveFilters}</div>
      </div>
      {userInfo?.uuid && <VideoList row={{name: 'My Favorites'}} />}
      {equipment &&
        equipment.map(row => <VideoList key={row.name} row={row} />)}
    </Section>
  );
};

export default Ondemand;

const Section = styled.div`
  max-width: 95vw;
  min-width: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .on-demand-row {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 20px 10px;
    padding: 7px;
    color: ${props => props.theme.colors.white_main};
    width: 95%;
    border-bottom: solid 3px ${props => props.theme.colors.grey_3};
  }

  .on-demand-header-container {
    display: flex;
    align-items: flex-end;
  }

  .clear-filters-btn {
    border-radius: 8px;
    border: 1px solid ${props => props.theme.colors.grey_6};
    color: ${props => props.theme.colors.grey_6};
    padding: 5px 10px;
    font-size: 12px;
    margin-left: 20px;
    cursor: pointer;
  }

  .active-filter {
    &-container {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
      margin-top: 15px;
      cursor: pointer;
    }
    &-btn {
      display: inline-flex;
      justitfy-content: space-around;
      align-items: center;
      border-radius: 8px;
      background-color: ${props => props.theme.colors.teal_main};
      padding: 5px 10px;
      margin: 5px;
    }
    &-x {
      font-size: 10px;
      color: ${props => props.theme.colors.black};
      margin-right: 10px;
    }
    &-name {
      font-size: 12px;
      color: ${props => props.theme.colors.white_main};
    }
  }

  @media (max-width: 425px) {
    margin-left: 10px;
  }
`;
