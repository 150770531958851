import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import CancelSubErrorModal from '../../common/Modals/CancelSubError';
import {useDispatch, useSelector} from 'react-redux';
import {user} from '../../../utilities/apiCalls';
import {setUser} from '../../../redux/actions/user';
import {useHistory} from 'react-router-dom';
import BackButton from '../../common/BackButton';

const CancelThanks = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userID = useSelector(state => state.user.id);
  const [state, setState] = useState({showModal: false, cancelError: false});

  useEffect(() => {
    const handleCancelSubscription = async () => {
      user.cancelSubscription().then(payload => {
        if (payload.data) {
          user
            .getUserInfo(userID)
            .then(userPayload => dispatch(setUser(userPayload.data)));
        } else {
          setState({showModal: true, cancelError: true});
        }
      });
    };

    handleCancelSubscription().then();
  }, [dispatch, userID]);

  return (
    <MainWrapper>
      <CancelSubErrorModal showModal={state.showModal} history={history} />
      <BackButton routeTo={'/account'} label={'Back to Account'} />
      <ContentContainer>
        {state.cancelError ? (
          <div className="container-header">Error Canceling</div>
        ) : (
          <div className="container-header">Subscription Canceled</div>
        )}
        {!state.cancelError && (
          <div className="container-text-container">
            <div className="container-text-info-big">
              We’re sorry to lose you, but we’re not going anywhere. We hope you
              enjoy everything our Basic subscription has to offer, and you can
              upgrade to Premium again any time. In the meantime, we’re going to
              be hard at work using your feedback to improve.
            </div>
            <div className="container-text-info-big">
              Thanks for keeping us inspired!
            </div>
          </div>
        )}
      </ContentContainer>
    </MainWrapper>
  );
};

export default CancelThanks;

const MainWrapper = styled.div`
  max-width: 600px;
`;

const ContentContainer = styled.div`
  color: ${props => props.theme.colors.white_main};
  margin: 50px 10px 0px 60px;

  @media (max-width: 750px) {
    margin: 10px 10px 0px 20px;
  }

  .container {
    &-text-container {
      margin-top: 30px;
    }

    &-header {
      font-size: 28px;
      line-height: 1.3;
    }

    &-text-info-big {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 30px;
    }
  }
`;
