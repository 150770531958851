import React from 'react';
import styled from 'styled-components';

const TrainerCard = props => {
  const selected = props.trainers.some(e => e.uuid === props.trainer.uuid);

  return (
    <MainWrapper
      selected={selected}
      key={props.trainer.first_name + props.trainer.last_name}
      onClick={() => props.handelFilters('trainer', props.trainer)}>
      <img
        src={
          props.trainer.image_url === ''
            ? 'https://s3-us-west-1.amazonaws.com/inspire-studios-assets/instructor-images/inspire_logo_black_globe.png'
            : props.trainer.image_url
        }
        alt={''}
      />
      <span className="overflow">
        {`${props.trainer.first_name}  ${props.trainer.last_name}`}
      </span>
    </MainWrapper>
  );
};

export default TrainerCard;

const MainWrapper = styled.section`
  display: flex;
  align-content: center;
  padding: 10px;
  cursor: pointer;

  .overflow {
    overflow-x: hidden;
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${props =>
    props.selected
      ? `background-color: ${props.theme.colors.teal_main} !important;
      font-weight: 600;`
      : ''}

  &:hover {
    font-weight: 500;
  }

  & img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 8px;
    background-color: ${props => props.theme.colors.white_main};
  }

  & span {
    margin: auto 0 auto 10px;
  }

  &-all {
    cursor: pointer;
    padding: 10px 0 10px calc(50% - 50px);
    color: ${props => props.theme.colors.orange_main};
    width: 50px;
    margin: 0 auto;
  }

  &-list {
    overflow: hidden;
    max-height: 280px;
    margin-left: -20px;

    &--all {
      max-height: initial;
    }
  }
`;
