import {SET_USER, UPDATE_SUBSCRIPTION_INFO} from './typeConstants';

export const setUser = data => ({
  type: SET_USER,
  data
});

export const updateSubscriptionInfo = data => ({
  type: UPDATE_SUBSCRIPTION_INFO,
  data
});
