import React from 'react';
import {useDispatch} from 'react-redux';
import {favorites} from '../../../utilities/apiCalls';
import BookmarkIcon from '../../../assets/images/BookmarkIcon';
import {setRefreshSessions} from '../../../redux/actions/filters';

const Bookmark = props => {
  const dispatch = useDispatch();
  const favorited = props.videoInfo?.is_favorited;

  return (
    <div
      onClick={e => {
        e.stopPropagation();
        if (!favorited) {
          favorites.add(props.videoInfo.uuid).then(payload => {
            if (!payload.error) {
              dispatch(setRefreshSessions());
            }
          });
        } else {
          favorites.delete(props.videoInfo.uuid).then(payload => {
            if (!payload.error) {
              dispatch(setRefreshSessions());
            }
          });
        }
      }}
      style={{
        ...props.style
      }}>
      <BookmarkIcon bookmarked={favorited} videoPage={props.videoPage} />
    </div>
  );
};
export default Bookmark;
