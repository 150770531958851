import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {getColors} from '../../../assets/styles/colors';
import {textButton} from '../../../assets/styles/common';
import createFormStyles from '../../../assets/styles/createFormStyles';
import {FBPixel} from '../../../utilities/facebookPixel';
import Footer from '../../common/Footer';
import AccountExists from '../../common/Modals/AccountExists';
import TopLogo from '../../common/TopLogo';
import AccountForm from './AccountForm';

const CreateAccount = () => {
  const colors = getColors();
  const [processing, setProcessing] = useState(false);
  const [accountExists, setAccountExists] = useState(false);
  const styles = createFormStyles({processing});
  const history = useHistory();

  useEffect(() => {
    FBPixel.PageView();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <AccountExists
        showModal={accountExists}
        setAccountExists={setAccountExists}
      />
      <TopLogo style={{margin: '20px 0 0 0', width: 300}} />
      <MainWrapper>
        <p style={{...styles.title, marginBottom: 5}}>Create your account</p>
        <p>Fill out the form below to create your new account.</p>
        <AccountForm
          processing={processing}
          setProcessing={setProcessing}
          setAccountExists={setAccountExists}
          styles={styles}
        />
        <p style={styles.createAccount}>
          Already have an account?
          <button
            style={{
              ...textButton,
              color: colors.orange_main,
              fontWeight: 'bold',
              fontSize: 16,
              marginLeft: 5
            }}
            onClick={() => {
              history.push('/login');
            }}>
            Sign in.
          </button>
        </p>
      </MainWrapper>
      <Footer />
    </div>
  );
};

export default CreateAccount;

const MainWrapper = styled.div`
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  padding: 25px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.white_main};

  .terms-container {
    margin-top: 50px;
  }

  .terms-link {
    color: ${props => props.theme.colors.white_main};
    font-size: 14px;
    text-decoration: none;
  }
`;
