import React, {useState} from 'react';

import Footer from '../common/Footer';
import SignInForm from './SignInForm';
import TopLogo from '../common/TopLogo';
import createFormStyles from '../../assets/styles/createFormStyles';
import {getColors} from '../../assets/styles/colors';
import styled from 'styled-components';
import {textButton} from '../../assets/styles/common';
import {useHistory} from 'react-router-dom';

const SignIn = () => {
  const history = useHistory();
  const colors = getColors();
  const [processing, setProcessing] = useState(false);
  const styles = createFormStyles({processing});
  console.log('')
  return (
    <div>
      <TopLogo style={{margin: '20px 0 0 20px '}} />
      <MainWrapper>
        <h2>Sign In</h2>
        <SignInForm
          processing={processing}
          setProcessing={setProcessing}
          styles={styles}
        />
        <p style={styles.createAccount}>
          New to Inspire?{' '}
          <button
            style={{...textButton, color: colors.orange_main}}
            onClick={() => {
              history.push('/subscription');
            }}>
            Create an account.
          </button>
        </p>
      </MainWrapper>
      <Footer />
    </div>
  );
};

export default SignIn;

const MainWrapper = styled.div`
  max-width: 350px;
  margin: 0 auto;
  padding: 25px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.white_main};

  & h2 {
    font-size: 28px;
    margin-bottom: 69px;

    @media (max-width: 450px) {
      margin: 10px 0;
    }
  }
`;
