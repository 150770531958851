import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Details = props => {
  const equipment =
    props.videoInfo?.equipment?.length ||
    props.videoInfo?.equipment_groups?.length
      ? [
          ...props.videoInfo.equipment,
          ...props.videoInfo.equipment_groups
        ].join(', ')
      : 'N/A';
  const music = props.videoInfo.music.join(', ');
  const duration = Math.round(props.videoInfo.expected_duration / 60);
  const instructorNames =
    props.videoInfo && props.videoInfo.instructors
      ? props.videoInfo.instructors.map(
          item => `${item.first_name} ${item.last_name}`
        )
      : ['N/A'];
  const startTime = moment(props.videoInfo.publish_date).format('ddd L LT');

  return (
    <MainWrapper>
      <p style={{fontSize: '.825em'}}>{`${duration} min • ${music}`}</p>
      <p style={{fontSize: '1.125em', fontWeight: 'bold'}}>
        {props.videoInfo.name}
      </p>
      <p style={{fontSize: '.75em'}}>{`${instructorNames.join(
        ', '
      )} • ${equipment}`}</p>
      <p className={'startTime'}>{startTime}</p>
    </MainWrapper>
  );
};

export default Details;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: left;
  z-index: 10;
  color: ${props => props.theme.colors.white_main};
  position: absolute;
  bottom: 5%;
  left: 2.5%;

  & p {
    margin: 1px;
    align-text: left;
  }

  .startTime {
    font-size: 0.7em;
    color: ${props => props.theme.colors.orange_main};
  }
`;
