import {
  SET_BILLING_INFO,
  SET_PAYMENT_STATE,
  SET_PROMO_STATE
} from '../actions/typeConstants';

const defaultState = {paymentState: '', promoState: '', billingInfo: {}};

const registration = (state = defaultState, action) => {
  let newState = {...state};

  switch (action.type) {
    case SET_PAYMENT_STATE:
      newState.paymentState = action.data;
      return newState;

    case SET_PROMO_STATE:
      if (!action.data) {
        return state;
      }
      newState.promoState = action.data;
      return newState;

    case SET_BILLING_INFO:
      if (!action.data) {
        return state;
      }
      newState.billingInfo = action.data;
      return newState;

    default:
      return state;
  }
};

export default registration;

export const paymentStates = {
  billing: 'BILLING',
  card: 'CARD',
  success: 'SUCCESS'
};

export const promoStates = {
  visible: 'VISIBLE',
  success: 'SUCCESS'
};
