import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import createFormStyles from '../../../assets/styles/createFormStyles';
import {FBPixel} from '../../../utilities/facebookPixel';
import Footer from '../../common/Footer';
import AccountExists from '../../common/Modals/AccountExists';
import TopLogo from '../../common/TopLogo';
import VerificationBody from './Verification';

const Verification = () => {
  const [processing, setProcessing] = useState(false);
  const [accountExists, setAccountExists] = useState(false);
  const styles = createFormStyles({processing});

  useEffect(() => {
    FBPixel.PageView();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <AccountExists
        showModal={accountExists}
        setAccountExists={setAccountExists}
      />
      <TopLogo style={{margin: '20px 0 0 0', width: 300}} />
      <MainWrapper>
        <VerificationBody
          processing={processing}
          setProcessing={setProcessing}
          setAccountExists={setAccountExists}
          styles={styles}
        />
      </MainWrapper>
      <Footer />
    </div>
  );
};

export default Verification;

const MainWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 25px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.white_main};

  .terms-container {
    margin-top: 50px;
  }

  .terms-link {
    color: ${props => props.theme.colors.white_main};
    line-height: 1.3;
    font-size: 14px;
    text-decoration: none;
  }
`;
