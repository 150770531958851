import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import Ondemand from './Ondemand';
import Live from './Live';
import SideMenu from '../Nav/SideMenu';
import OndemandFilters from '../classes/Ondemand/Filters';
import {setMetadata, setTrainers} from '../../redux/actions/config';
import {metadataV2, trainers} from '../../utilities/apiCalls';

const Classes = () => {
  const dispatch = useDispatch();
  const filterActive = useSelector(state => state.modals.classes.filters);

  useEffect(() => {
    trainers.get('').then(payload =>
      dispatch(
        setTrainers(
          payload.data.sort((a, b) => {
            if (a.lastName < b.lastName) {
              return -1;
            }
            if (a.lastName > b.lastName) {
              return 1;
            }
            return 0;
          })
        )
      )
    );
    metadataV2.get('').then(payload => {
      if (!payload.error) {
        dispatch(setMetadata(payload.data));
      }
    });
  }, [dispatch]);

  return (
    <MainWrapper filterActive={filterActive}>
      <SideMenu />
      <OndemandFilters />
      <Live />
      <Ondemand />
    </MainWrapper>
  );
};

export default Classes;

const MainWrapper = styled.div`
  background-color: ${props => props.theme.colors.grey_base};

  margin-left: ${props => (props.filterActive ? '600px' : '250px')};

  @media (max-width: 770px) {
    margin-left: 0};
  }

  @media (min-width: 771) {
    margin-left: ${props => (props.filterActive ? '250px' : '0')};
  }

  > * {
    grid-column: 2 / -2;
  }

  .font-weight-light {
    font-weight: lighter;
    margin: 0px 0px 0px 20px;
  }

  .classes {
    color: ${props => props.theme.colors.white_main};
    margin: 45px 0px 0px 30px;
    font-weight: 500;
  }

  .filter {
    max-height: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
  }

  .filters {
    &-icon {
      width: 20px;
    }

    &-btn {
      background-color: rgb(27, 28, 28);
      border: none;
      cursor: pointer;
      padding-left: 10px;
      padding-top: 5px;
    }
  }

  .workout-type {
    margin: 40px 0px 10px 35px;
    color: ${props => props.theme.colors.white_main};
  }
`;
