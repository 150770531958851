import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import styled from 'styled-components';
import Error from '../../../common/Error';
import MessageBox from '../../../common/MessageBox';
import createFormStyles from '../../../../assets/styles/createFormStyles';
import {textButton} from '../../../../assets/styles/common';
import {useDispatch, useSelector} from 'react-redux';
import {
  setPaymentState,
  setBillingInfo
} from '../../../../redux/actions/registration';
import {paymentStates} from '../../../../redux/reducers/registration';
import {getColors} from '../../../../assets/styles/colors';

const AccountForm = () => {
  const dispatch = useDispatch();
  const styles = createFormStyles({processing: false});
  const billingInfo = useSelector(state => state.registration.billingInfo);
  const storedPayment = useSelector(state => state.user.paymentMethod);
  const {register, errors, handleSubmit, setValue} = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const zipCodeOther = /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i;

  useEffect(() => {
    if (storedPayment && Object.keys(storedPayment).length) {
      const billing = storedPayment.billing_details;
      const address = billing.address;

      setValue('name', billing.name);
      setValue('address_line1', address.line1);
      setValue('address_line2', address.line2);
      setValue('address_city', address.city);
      setValue('address_state', address.state);
      setValue('address_zip', address.postal_code);
      setValue('address_country', address.country);
    } else if (Object.keys(billingInfo).length) {
      setValue('name', billingInfo.name);
      setValue('address_line1', billingInfo.address_line1);
      setValue('address_line2', billingInfo.address_line2);
      setValue('address_city', billingInfo.address_city);
      setValue('address_state', billingInfo.address_state);
      setValue('address_zip', billingInfo.address_zip);
      setValue('address_country', billingInfo.address_country);
    }
  }, [setValue, billingInfo, storedPayment]);

  const onSubmit = async event => {
    const info = {
      name: event.name,
      address_line1: event.address_line1,
      address_line2: event.address_line2,
      address_city: event.address_city,
      address_state: event.address_state,
      address_zip: event.address_zip,
      address_country: event.address_country
    };
    setSaveError(false);
    setSubmitted(false);
    dispatch(setBillingInfo(info));
    dispatch(setPaymentState(paymentStates.card));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
      <p style={{...styles.subTitle, textAlign: 'left'}}>
        1. Billing Information
      </p>

      <div style={styles.label}>
        <label style={{paddingRight: 5}}>Full Name</label>
        <Error message={errors.name?.message ? errors.name.message : ''} />
      </div>
      <input
        style={errors.name ? styles.hasError : styles.input}
        name={'name'}
        type={'text'}
        ref={register({
          required: {value: true, message: 'Required'}
        })}
      />
      <div style={styles.label}>
        <label style={{paddingRight: 5}}>Address Line 1</label>
        <Error
          message={
            errors.address_line1?.message ? errors.address_line1.message : ''
          }
        />
      </div>
      <input
        style={errors.address_line1 ? styles.hasError : styles.input}
        name={'address_line1'}
        type={'text'}
        ref={register({
          required: {value: true, message: 'Required'}
        })}
      />
      <div style={styles.label}>
        <label style={{paddingRight: 5}}>Address Line 2 (optional)</label>
        <Error
          message={
            errors.address_line2?.message ? errors.address_line2.message : ''
          }
        />
      </div>
      <input
        style={errors.address_line2 ? styles.hasError : styles.input}
        name={'address_line2'}
        type={'text'}
        ref={register({
          required: {value: false, message: ''}
        })}
      />

      <SmallGrid>
        <div>
          <div style={styles.label}>
            <label style={{paddingRight: 5}}>City/Town</label>
            <Error
              message={
                errors.address_city?.message ? errors.address_city.message : ''
              }
            />
          </div>
          <input
            style={errors.address_city ? styles.hasError : styles.input}
            name={'address_city'}
            type={'text'}
            ref={register({
              required: {value: true, message: 'Required'}
            })}
          />
        </div>
        <div>
          <div style={styles.label}>
            <label style={{paddingRight: 5}}>State/Province/Region</label>
            <Error
              message={
                errors.address_state?.message
                  ? errors.address_state.message
                  : ''
              }
            />
          </div>
          <input
            style={errors.address_state ? styles.hasError : styles.input}
            name={'address_state'}
            type={'text'}
            ref={register({
              required: {value: true, message: 'Required'}
            })}
          />
        </div>
        <div>
          <div style={styles.label}>
            <label style={{paddingRight: 5}}>Postal Code</label>
            <Error
              message={
                errors.address_zip?.message ? errors.address_zip.message : ''
              }
            />
          </div>
          <input
            style={errors.address_zip ? styles.hasError : styles.input}
            name={'address_zip'}
            type={'text'}
            ref={register({
              required: {
                value: true,
                message: 'Required',
                pattern: zipCodeOther
              }
            })}
          />
        </div>
        <div>
          <div style={styles.label}>
            <label style={{paddingRight: 5}}>Country</label>
            <Error
              message={
                errors.address_country?.message
                  ? errors.address_country.message
                  : ''
              }
            />
          </div>
          <input
            style={errors.address_country ? styles.hasError : styles.input}
            name={'address_country'}
            type={'text'}
            ref={register({
              required: {value: true, message: 'Required'}
            })}
          />
        </div>
      </SmallGrid>

      <input
        type="submit"
        style={{
          ...textButton,
          padding: '20px 0',
          alignSelf: 'flex-end',
          color: getColors().orange_main,
          fontSize: '1em',
          fontWeight: '600'
        }}
        value={'Save & Continue'}
      />

      <NextStep>
        <p>2. Credit Card Information</p>
      </NextStep>

      <MessageBox
        show={submitted}
        message={saveError ? 'Account created.' : 'Error creating account.'}
        error={!saveError}
      />
    </Form>
  );
};

export default AccountForm;

const SmallGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;

  @media (max-width: 450px) {
    grid-column-gap: 5px;

    & label {
      font-size: 0.875em;
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: 450px) {
    width: 90vw;
  }
`;

const NextStep = styled.div`
  border-top: 1px solid ${props => props.theme.colors.white_main};
`;
